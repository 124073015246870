import {
  parseFloatNumbersWithOneDigitAfterPoint,
  validateMinimalSocLessThanMaximalSoc,
  setDefaultLimitForCtaHeatPumpFields,
  onlyZeroAndLowerNegativesInteger,
  parseOnlyPositiveIntegerNumbers,
  parseStringOfLettersAndNumbers,
  parsePositiveNumbersOrNothing,
  parseIntegerNumbersNotStrict,
  parsePositiveAndZeroNumbers,
  parseStrictPositiveNumbers,
  parsePositiveIntegerOrZero,
  parseOnlyPositiveNumbers,
  // parseOnlyNegativeNumbers,
  parseIntegerNumbers,
  toBeOneOrPositive,
  betweenPlusMinus2,
  parseOnlyNumbers,
  validateFloat,
  from100to9000,
  parseBoolean,
  validationIP,
  validatePort,
  // toBeInteger,
  timeParser,
  from0to100,
  from6to32,
  minLength,
  maxLength,
  required,
  time,
  onlyZeroAndHigherPositives,
  from0to7,
  from1to100,
  validateFromLessOrEqualToTo,
  requiredDays,
  fromMinus2to0,
  from0to2,
  from0to10,
  createRangeValidationForDevice,
  validateFloatWithTwoDecimal,
  requiredMultiple,
  minFloat,
  parseOnlyPositiveIntegerOrZero,
  parseFloatMinusAndPlusNumbers,
  validateNumbersWith1DigitStep05
} from '../../validation';

import { CustomTextInputHoc } from '../../../components/ReduxFormFields/CustomTextInput/CustomTextInputHoc';
import { CustomTextInput } from '../../../components/ReduxFormFields/CustomTextInput';
import { CustomTextField } from '../../../components/ReduxFormFields/CustomTextField';
import { SwitchingTimes } from '../../../components/ReduxFormFields/SwitchingTimes';
import { MultipleSelect } from '../../../components/ReduxFormFields/MultipleSelect';
import { TokenString } from '../../../components/ReduxFormFields/TokenString';
import { CallbackURL } from '../../../components/ReduxFormFields/CallbackURL';
import { TimePickerInput } from '../../../components/ReduxFormFields/SwitchingTimes/components/TimePickerInput';
import { Checkbox } from '../../../components/ReduxFormFields/Checkbox';
import DateTimePicker from '../../../components/ReduxFormFields/DateTimePicker/DateTimePicker';
import Select from '../components/Select';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';
import { OcppUrlField, SearchChargersField } from '../../../components/ReduxFormFields';
import SelectDays from '../../../components/ReduxFormFields/SwitchingTimes/components/SelectDays';
import { RadioButtons } from '../../../components/ReduxFormFields/RadioButtons';

import i18n from '../../../i18n';
import { getOptionsWithRestrictions } from './getOptionsWithRestrictions';
import { SkiSearchField } from '../../../components/ReduxFormFields/SkiSearchField';
import { openModalWindow } from '../../ModalWindow/slice';
import { EXPERT_MODAL_ID } from '../../../modals';

const maxLen50 = maxLength(50);
const minLen3 = minLength(1);
const minFloatValue1 = minFloat(1, 'validations.above_1_and_positives_with_2_digits');

const ctaRtuFrom20to55Validation = createRangeValidationForDevice('CTA heat pump RTU', 20, 25);

/**
 * Object of ALL additional fields for devices
 * @constant
 * @memberof module:UserDevices
 * @type {Object}
 */
const additionalFields = {
  activeDevicePowerLimit: {
    label: 'activeDevicePowerLimit.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'activeDevicePowerLimit.tooltip',
    enabledIf: [
      {
        fieldID: 'activeDevicePowerLimitEnable',
        values: [true]
      }
    ]
  },
  activeDevicePowerLimitEnable: {
    label: 'activeDevicePowerLimitEnable.label',
    showTooltip: 'activeDevicePowerLimitEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  Baudrate: {
    label: 'Baudrate.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  index: {
    label: i18n.t('index.label'),
    showTooltip: 'index.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required]
  },
  blockingSetting: {
    label: 'blockingSetting.label',
    component: Select,
    options: () => [
      { label: i18n.t('blockingSetting.options.1'), value: 1 },
      { label: i18n.t('blockingSetting.options.2'), value: 2 },
      { label: i18n.t('blockingSetting.options.3'), value: 3 },
      { label: i18n.t('blockingSetting.options.4'), value: 4 }
    ],
    className: 'm-input',
    showTooltip: 'blockingSetting.tooltip',
    validate: [required],
    required: true
  },
  boilerTempOverproductionEnable: {
    label: 'boilerTempOverproductionEnable.label',
    showTooltip: 'boilerTempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  boilerTempOverproduction: {
    label: 'boilerTempOverproduction.label',
    showTooltip: 'boilerTempOverproduction.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOverproductionEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'boilerTempOverproduction',
      validate: [setDefaultLimitForCtaHeatPumpFields]
    }
  },
  boilerTempNormal: {
    label: 'boilerTempNormal.label',
    showTooltip: 'boilerTempNormal.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOverproductionEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'boilerTempNormal',
      validate: [setDefaultLimitForCtaHeatPumpFields]
    }
  },
  boilerTempReduced: {
    label: 'boilerTempReduced.label',
    showTooltip: 'boilerTempReduced.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOverproductionEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'boilerTempReduced',
      validate: [setDefaultLimitForCtaHeatPumpFields]
    }
  },
  boilerTempOffsetEnable: {
    label: 'boilerTempOffsetEnable.label',
    showTooltip: 'boilerTempOffsetEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  boilerTempOffsetMaximum: {
    label: 'boilerTempOffsetMaximum.label',
    showTooltip: 'boilerTempOffsetMaximum.tooltip',
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    parse: parsePositiveIntegerOrZero,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOffsetEnable',
        values: [true]
      }
    ]
  },
  boilerTempOffsetMinimum: {
    label: 'boilerTempOffsetMinimum.label',
    showTooltip: 'boilerTempOffsetMinimum.tooltip',
    parse: parseIntegerNumbersNotStrict,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, onlyZeroAndLowerNegativesInteger],
    required: true,
    enabledIf: [
      {
        fieldID: 'boilerTempOffsetEnable',
        values: [true]
      }
    ]
  },
  ChargerID: {
    label: 'ChargerID.label',
    showTooltip: 'ChargerID.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  chargerIDMaster: {
    label: 'chargerIDMaster.label',
    showTooltip: 'chargerIDMaster.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  heatPumpMode: {
    label: 'heatPumpMode.label',
    component: Select,
    className: 'm-input',
    options: () => [
      { label: i18n.t('heatPumpMode.options.0'), value: 0 },
      { label: i18n.t('heatPumpMode.options.1'), value: 1 }
    ],
    validate: [required],
    required: true
  },
  heatModeAuto: {
    label: 'heatModeAuto.label',
    component: Select,
    className: 'm-input',
    options: () => [
      { label: i18n.t('heatModeAuto.options.0'), value: true },
      { label: i18n.t('heatModeAuto.options.1'), value: false }
    ],
    validate: [required],
    required: true
  },
  heaterstep: {
    label: 'heaterstep.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, from0to7],
    parse: parseOnlyNumbers,
    required: true
  },
  powerSettingPercent: {
    label: 'powerSettingPercent.label',
    showTooltip: 'powerSettingPercent.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, from0to100],
    parse: parseOnlyNumbers,
    required: true,
    switchAction: {
      defaultValue: 100,
      checkIfEnabled: (deviceType, switchingMode) => ['Water Heater'].includes(deviceType) && switchingMode === 1
    }
  },
  chargingMode: {
    label: 'chargingMode.label',
    showTooltip: 'chargingMode.tooltip',
    component: Select,
    options: ({ deviceType, mode, isCarExists, license, userApp }) => {
      const options = [
        { label: i18n.t('chargingMode.options.0'), value: 0 },
        { label: i18n.t('chargingMode.options.1'), value: 1 },
        { label: i18n.t('chargingMode.options.2'), value: 2 },
        { label: i18n.t('chargingMode.options.5'), value: 5 },
        { label: i18n.t('chargingMode.options.3'), value: 3 },
        { label: i18n.t('chargingMode.options.4'), value: 4 }
      ];

      if (['V2X'].includes(deviceType)) {
        options.splice(4, 0, { label: i18n.t('chargingMode.options.6'), value: 6 });
      }

      if (['Car Charging'].includes(deviceType)) {
        options.splice(4, 0, { label: i18n.t('chargingMode.options.6'), value: 6 });
        if ((isCarExists || mode === 'add') && !license?.restrictions?.isCarRestricted) {
          options.push({ label: i18n.t('chargingMode.options.7'), value: 7 });
        }
      }

      if (userApp === 'thermondoEndUserApp') {
        if (license?.restrictions?.allowedModes?.chargingMode) {
          return getOptionsWithRestrictions(options, license.restrictions.allowedModes.chargingMode, license.type);
        }
      }

      return options;
    },
    className: 'm-input',
    validate: [required],
    required: true
  },
  homeApplianceChargingMode: {
    label: 'homeApplianceChargingMode.label',
    showTooltip: 'homeApplianceChargingMode.tooltip',
    component: Select,
    options: ({ license }) => {
      const options = [
        { label: i18n.t('homeApplianceChargingMode.options.1'), value: 1 },
        { label: i18n.t('homeApplianceChargingMode.options.2'), value: 2 },
        { label: i18n.t('homeApplianceChargingMode.options.3'), value: 3 },
        { label: i18n.t('homeApplianceChargingMode.options.4'), value: 4 }
      ];

      if (license?.restrictions?.allowedModes?.homeApplianceChargingMode) {
        return getOptionsWithRestrictions(options, license.restrictions.allowedModes.homeApplianceChargingMode, license.type);
      }

      return options;
    },
    className: 'm-input',
    validate: [required],
    required: true
  },
  constantCurrentSetting: {
    label: 'constantCurrentSetting.label',
    showTooltip: 'constantCurrentSetting.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from6to32],
    required: true,
    enabledIf: [
      {
        fieldID: 'chargingMode',
        values: [4]
      }
    ],
    switchAction: {
      defaultValue: 10,
      changeTooltipPosition: { right: '-30px' },
      checkIfEnabled: (deviceType, switchingMode) => ['Car Charging', 'V2X'].includes(deviceType) && switchingMode === 4
    }
  },
  ComfortOffsetMaximum: {
    label: 'ComfortOffsetMaximum.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validateNumbersWith1DigitStep05],
    required: true,
    parse: parseFloatMinusAndPlusNumbers,
    showTooltip: 'ComfortOffsetMaximum.tooltip',
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'ComfortOffsetMaximum',
      validate: [from0to2]
    }
  },
  ComfortOffsetMinimum: {
    label: 'ComfortOffsetMinimum.label',
    component: CustomTextInputHoc,
    className: 'm-input',
    validate: [required, validateNumbersWith1DigitStep05],
    required: true,
    parse: parseFloatMinusAndPlusNumbers,
    showTooltip: 'ComfortOffsetMinimum.tooltip',
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'ComfortOffsetMinimum',
      validate: [fromMinus2to0]
    }
  },
  ComfortOffsetNormal: {
    label: 'ComfortOffsetNormal.label',
    showTooltip: 'ComfortOffsetNormal.tooltip',
    component: CustomTextInputHoc,
    className: 'm-input',
    validate: [required, validateNumbersWith1DigitStep05],
    required: true,
    parse: parseFloatMinusAndPlusNumbers,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'ComfortOffsetMinimum',
      validate: [fromMinus2to0]
    }
  },
  ComfortOffsetMinimum2: {
    label: 'ComfortOffsetMinimum2.label',
    showTooltip: 'ComfortOffsetMinimum2.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validateNumbersWith1DigitStep05],
    parse: parseFloatMinusAndPlusNumbers,
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable2',
        values: [true]
      }
    ]
  },
  ComfortOffsetNormal2: {
    label: 'ComfortOffsetNormal2.label',
    showTooltip: 'ComfortOffsetNormal2.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validateNumbersWith1DigitStep05],
    required: true,
    parse: parseFloatMinusAndPlusNumbers,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable2',
        values: [true]
      }
    ]
  },
  ComfortOffsetMaximum2: {
    label: 'ComfortOffsetMaximum2.label',
    showTooltip: 'ComfortOffsetMaximum2.tooltip',
    validate: [required, validateNumbersWith1DigitStep05],
    required: true,
    parse: parseFloatMinusAndPlusNumbers,
    className: 'm-input',
    component: CustomTextInput,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable2',
        values: [true]
      }
    ]
  },
  comfortOffsetEnable: {
    label: 'comfortOffsetEnable.label',
    showTooltip: 'comfortOffsetEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  comfortOffsetEnable2: {
    label: 'comfortOffsetEnable2.label',
    showTooltip: 'comfortOffsetEnable2.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  carType: {
    label: 'carType.label',
    showTooltip: 'carType.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('carType.options.0'), value: 0 },
      { label: i18n.t('carType.options.1'), value: 1 },
      { label: i18n.t('carType.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  chargingType: {
    label: 'chargingType',
    component: Select,
    options: () => [
      { label: i18n.t('chargeWithSolarPower'), value: 0 },
      { label: i18n.t('chargeInLowTariff'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  chargingCableNr: {
    label: 'chargingCableNr.label',
    showTooltip: 'chargingCableNr.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('chargingCableNr.options.0'), value: 0 },
      { label: i18n.t('chargingCableNr.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  controlMethod: {
    label: 'controlMethod.label',
    component: Select,
    options: () => [
      { label: i18n.t('controlMethod.options.0'), value: 0 },
      { label: i18n.t('controlMethod.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'controlMethod.tooltip'
  },
  controlMethod2: {
    label: 'controlMethod2.label',
    component: Select,
    options: () => [
      { label: i18n.t('controlMethod2.options.0'), value: 0 },
      { label: i18n.t('controlMethod2.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'controlMethod2.tooltip'
  },
  ctaType: {
    label: 'ctaType.label',
    showTooltip: 'ctaType.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('ctaType.options.0'), value: 0 },
      { label: i18n.t('ctaType.options.1'), value: 1 },
      { label: i18n.t('ctaType.options.2'), value: 2 },
      { label: i18n.t('ctaType.options.3'), value: 3 },
      { label: i18n.t('ctaType.options.4'), value: 4 },
      { label: i18n.t('ctaType.options.5'), value: 5 },
      { label: i18n.t('ctaType.options.6'), value: 6 },
      { label: i18n.t('ctaType.options.7'), value: 7 },
      { label: i18n.t('ctaType.options.8'), value: 8 },
      { label: i18n.t('ctaType.options.9'), value: 9 },
      { label: i18n.t('ctaType.options.10'), value: 10 },
      { label: i18n.t('ctaType.options.11'), value: 11 },
      { label: i18n.t('ctaType.options.12'), value: 12 },
      { label: i18n.t('ctaType.options.13'), value: 13 },
      { label: i18n.t('ctaType.options.14'), value: 14 },
      { label: i18n.t('ctaType.options.15'), value: 15 },
      { label: i18n.t('ctaType.options.16'), value: 16 },
      { label: i18n.t('ctaType.options.17'), value: 17 },
      { label: i18n.t('ctaType.options.18'), value: 18 },
      { label: i18n.t('ctaType.options.19'), value: 19 },
      { label: i18n.t('ctaType.options.20'), value: 20 },
      { label: i18n.t('ctaType.options.21'), value: 21 },
      { label: i18n.t('ctaType.options.22'), value: 22 },
      { label: i18n.t('ctaType.options.23'), value: 23 },
      { label: i18n.t('ctaType.options.24'), value: 24 },
      { label: i18n.t('ctaType.options.25'), value: 25 },
      { label: i18n.t('ctaType.options.26'), value: 26 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  ctaTypeCarel: {
    label: 'ctaTypeCarel.label',
    showTooltip: 'ctaTypeCarel.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('ctaTypeCarel.options.0'), value: 0 },
      { label: i18n.t('ctaTypeCarel.options.1'), value: 1 },
      { label: i18n.t('ctaTypeCarel.options.2'), value: 2 },
      { label: i18n.t('ctaTypeCarel.options.3'), value: 3 },
      { label: i18n.t('ctaTypeCarel.options.4'), value: 4 },
      { label: i18n.t('ctaTypeCarel.options.5'), value: 5 },
      { label: i18n.t('ctaTypeCarel.options.6'), value: 6 },
      { label: i18n.t('ctaTypeCarel.options.7'), value: 7 },
      { label: i18n.t('ctaTypeCarel.options.8'), value: 8 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  ctaTypeRTU: {
    label: 'ctaTypeRTU.label',
    showTooltip: 'ctaTypeRTU.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('ctaTypeRTU.options.0'), value: 0 },
      { label: i18n.t('ctaTypeRTU.options.1'), value: 1 },
      { label: i18n.t('ctaTypeRTU.options.2'), value: 2 },
      { label: i18n.t('ctaTypeRTU.options.3'), value: 3 },
      { label: i18n.t('ctaTypeRTU.options.4'), value: 4 },
      { label: i18n.t('ctaTypeRTU.options.5'), value: 5 },
      { label: i18n.t('ctaTypeRTU.options.6'), value: 6 },
      { label: i18n.t('ctaTypeRTU.options.7'), value: 7 },
      { label: i18n.t('ctaTypeRTU.options.8'), value: 8 },
      { label: i18n.t('ctaTypeRTU.options.9'), value: 9 },
      { label: i18n.t('ctaTypeRTU.options.10'), value: 10 },
      { label: i18n.t('ctaTypeRTU.options.11'), value: 11 },
      { label: i18n.t('ctaTypeRTU.options.12'), value: 12 },
      { label: i18n.t('ctaTypeRTU.options.13'), value: 13 },
      { label: i18n.t('ctaTypeRTU.options.14'), value: 14 },
      { label: i18n.t('ctaTypeRTU.options.15'), value: 15 },
      { label: i18n.t('ctaTypeRTU.options.16'), value: 16 },
      { label: i18n.t('ctaTypeRTU.options.17'), value: 17 },
      { label: i18n.t('ctaTypeRTU.options.18'), value: 18 },
      { label: i18n.t('ctaTypeRTU.options.19'), value: 19 },
      { label: i18n.t('ctaTypeRTU.options.20'), value: 20 },
      { label: i18n.t('ctaTypeRTU.options.21'), value: 21 },
      { label: i18n.t('ctaTypeRTU.options.22'), value: 22 },
      { label: i18n.t('ctaTypeRTU.options.23'), value: 23 },
      { label: i18n.t('ctaTypeRTU.options.24'), value: 24 },
      { label: i18n.t('ctaTypeRTU.options.25'), value: 25 },
      { label: i18n.t('ctaTypeRTU.options.26'), value: 26 },
      { label: i18n.t('ctaTypeRTU.options.27'), value: 27 },
      { label: i18n.t('ctaTypeRTU.options.28'), value: 28 },
      { label: i18n.t('ctaTypeRTU.options.29'), value: 29 },
      { label: i18n.t('ctaTypeRTU.options.30'), value: 30 },
      { label: i18n.t('ctaTypeRTU.options.31'), value: 31 },
      { label: i18n.t('ctaTypeRTU.options.32'), value: 32 },
      { label: i18n.t('ctaTypeRTU.options.33'), value: 33 },
      { label: i18n.t('ctaTypeRTU.options.34'), value: 34 },
      { label: i18n.t('ctaTypeRTU.options.35'), value: 35 },
      { label: i18n.t('ctaTypeRTU.options.36'), value: 36 },
      { label: i18n.t('ctaTypeRTU.options.37'), value: 37 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  Device_ID: {
    label: 'Device_ID.label',
    showTooltip: 'Device_ID.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  Device_ID_RTU: {
    label: 'Device_ID_RTU.label',
    showTooltip: 'Device_ID_RTU.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required],
    required: true
  },
  deviceTypeStiebel: {
    label: 'deviceTypeStiebel.label',
    showTooltip: 'deviceTypeStiebel.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('deviceTypeStiebel.options.1'), value: 1 },
      { label: i18n.t('deviceTypeStiebel.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  emuCenterSmartMeterType: {
    label: 'emuCenterSmartMeterType.label',
    showTooltip: 'emuCenterSmartMeterType.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('emuCenterSmartMeterType.options.0'), value: 0 },
      { label: i18n.t('emuCenterSmartMeterType.options.1'), value: 1 },
      { label: i18n.t('emuCenterSmartMeterType.options.2'), value: 2 },
      { label: i18n.t('emuCenterSmartMeterType.options.3'), value: 3 },
      { label: i18n.t('emuCenterSmartMeterType.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  roomDirectOrBufferEnable: {
    label: 'roomDirectOrBufferEnable.label',
    showTooltip: 'roomDirectOrBufferEnable.tooltip',
    type: 'checkbox',
    component: Checkbox,
    className: 'm-input',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    parse: parseBoolean
  },
  roomDirectOrBufferTempOverproduction: {
    label: 'roomDirectOrBufferTempOverproduction.label',
    showTooltip: 'roomDirectOrBufferTempOverproduction.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomDirectOrBufferEnable',
        values: [true]
      }
    ]
  },
  roomDirectOrBufferTempNormal: {
    label: 'roomDirectOrBufferTempNormal.label',
    showTooltip: 'roomDirectOrBufferTempNormal.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomDirectOrBufferEnable',
        values: [true]
      }
    ]
  },
  roomDirectOrBufferTempReduced: {
    label: 'roomDirectOrBufferTempReduced.label',
    showTooltip: 'roomDirectOrBufferTempReduced.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomDirectOrBufferEnable',
        values: [true]
      }
    ]
  },
  heatingStiebelHCDirect: {
    label: 'heatingStiebelHCDirect.label',
    showTooltip: 'heatingStiebelHCDirect.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('heatingStiebelHCDirect.options.0'), value: 0 },
      { label: i18n.t('heatingStiebelHCDirect.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomDirectOrBufferEnable',
        values: [true]
      }
    ]
  },
  heatingStiebelHC1: {
    label: 'heatingStiebelHC1.label',
    showTooltip: 'heatingStiebelHC1.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('heatingStiebelHC1.options.0'), value: 0 },
      { label: i18n.t('heatingStiebelHC1.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  heatingStiebelHC2: {
    label: 'heatingStiebelHC2.label',
    showTooltip: 'heatingStiebelHC2.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('heatingStiebelHC2.options.0'), value: 0 },
      { label: i18n.t('heatingStiebelHC2.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  heatingStiebelHC3: {
    label: 'heatingStiebelHC3.label',
    showTooltip: 'heatingStiebelHC3.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('heatingStiebelHC3.options.0'), value: 0 },
      { label: i18n.t('heatingStiebelHC3.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  enableLowTariff: {
    label: 'enableLowTariff.label',
    showTooltip: 'enableLowTariff.tooltip',
    type: 'checkbox',
    component: Checkbox,
    className: 'm-input',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    parse: parseBoolean
  },
  enableReducedCurrent: {
    label: 'enableReducedCurrent.label',
    showTooltip: 'enableReducedCurrent.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  excessIndicationLimit: {
    label: 'excessIndicationLimit.label',
    showTooltip: 'excessIndicationLimit.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  gridBlockEnable: {
    label: 'gridBlockEnable.label',
    showTooltip: 'gridBlockEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  gatewayNr: {
    label: 'gatewayNr.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'gatewayNr.tooltip'
  },
  gatewaypw: {
    label: 'gatewaypw.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    showTooltip: 'gatewaypw.tooltip'
  },
  heatingBufferOptimizationEnable: {
    label: 'heatingBufferOptimizationEnable.label',
    showTooltip: 'heatingBufferOptimizationEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingBufferOverproduction: {
    label: 'heatingBufferOverproduction.label',
    showTooltip: 'heatingBufferOverproduction.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ],
    validateIf: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      fieldName: 'heatingBufferOverproduction',
      validate: [from0to10]
    }
  },
  heatingBufferReduced: {
    label: 'heatingBufferReduced.label',
    showTooltip: 'heatingBufferReduced.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ]
  },
  heatingBufferOverprodAbs: {
    label: 'heatingBufferOverprodAbs.label',
    showTooltip: 'heatingBufferOverprodAbs.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ]
  },
  heatingBufferReducedAbs: {
    label: 'heatingBufferReducedAbs.label',
    showTooltip: 'heatingBufferReducedAbs.tooltip',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingBufferOptimizationEnable',
        values: [true]
      }
    ]
  },
  heatingCircuitDirectEnable: {
    label: 'heatingCircuitDirectEnable.label',
    showTooltip: 'heatingCircuitDirectEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit1Enable: {
    label: 'heatingCircuit1Enable.label',
    showTooltip: 'heatingCircuit1Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit2Enable: {
    label: 'heatingCircuit2Enable.label',
    showTooltip: 'heatingCircuit2Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit3Enable: {
    label: 'heatingCircuit3Enable.label',
    showTooltip: 'heatingCircuit3Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingCircuit4Enable: {
    label: 'heatingCircuit4Enable.label',
    showTooltip: 'heatingCircuit4Enable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingFlowTemperatureEnable: {
    label: 'heatingFlowTemperatureEnable.label',
    showTooltip: 'heatingFlowTemperatureEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  heatingFlowTemperatureOverproduction: {
    label: 'heatingFlowTemperatureOverproduction.label',
    showTooltip: 'heatingFlowTemperatureOverproduction.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingFlowTemperatureEnable',
        values: [true]
      }
    ]
  },
  heatingFlowTemperatureEnableReduction: {
    label: 'heatingFlowTemperatureEnableReduction.label',
    showTooltip: 'heatingFlowTemperatureEnableReduction.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingFlowTemperatureEnable',
        values: [true]
      }
    ]
  },
  heatpumpControlMethod: {
    label: 'heatpumpControlMethod.label',
    showTooltip: 'heatpumpControlMethod.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('heatpumpControlMethod.options.0'), value: 0 },
      { label: i18n.t('heatpumpControlMethod.options.7'), value: 7 },
      { label: i18n.t('heatpumpControlMethod.options.8'), value: 8 },
      { label: i18n.t('heatpumpControlMethod.options.9'), value: 9 },
      { label: i18n.t('heatpumpControlMethod.options.10'), value: 10 },
      { label: i18n.t('heatpumpControlMethod.options.11'), value: 11 }
    ]
  },

  identifier: {
    label: 'identifier.label',
    showTooltip: 'identifier.tooltip',
    required: true,
    validate: [required],
    className: 'm-input',
    component: CustomTextInput
  },
  intelligentCooling: {
    label: 'intelligentCooling.label',
    showTooltip: 'intelligentCooling.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('intelligentCooling.options.0'), value: 0 },
      { label: i18n.t('intelligentCooling.options.1'), value: 1 }
    ]
  },
  input: {
    label: 'input.label',
    component: Select,
    options: () => [
      { label: i18n.t('input.options.1'), value: 1 },
      { label: i18n.t('input.options.2'), value: 2 },
      { label: i18n.t('input.options.3'), value: 3 },
      { label: i18n.t('input.options.4'), value: 4 },
      { label: i18n.t('input.options.5'), value: 5 },
      { label: i18n.t('input.options.6'), value: 6 },
      { label: i18n.t('input.options.7'), value: 7 },
      { label: i18n.t('input.options.8'), value: 8 }
    ],
    className: 'm-input',
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'gridBlockEnable',
        values: [true]
      }
    ]
  },
  installLocation: {
    label: 'installLocation.label',
    showTooltip: 'installLocation.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('installLocation.options.0'), value: 0 },
      { label: i18n.t('installLocation.options.1'), value: 1 },
      { label: i18n.t('installLocation.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  installLocationRennergy: {
    label: 'installLocationRennergy.label',
    showTooltip: 'installLocationRennergy.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('installLocationRennergy.options.0'), value: 0 },
      { label: i18n.t('installLocationRennergy.options.1'), value: 1 },
      { label: i18n.t('installLocationRennergy.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  installationLocation: {
    label: 'installationLocation.label',
    showTooltip: 'installationLocation.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('installationLocation.options.0'), value: 0 },
      { label: i18n.t('installationLocation.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  inverterHeatPump: {
    label: 'inverterHeatPump.label',
    showTooltip: 'inverterHeatPump.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  invertMeasurement: {
    label: 'invertMeasurement.label',
    component: Select,
    options: () => [
      { label: i18n.t('invertMeasurement.options.false'), value: false },
      { label: i18n.t('invertMeasurement.options.true'), value: true }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  ip: {
    label: 'ip.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validationIP],
    required: true
  },
  deviceIP2: {
    label: 'deviceIP2.label',
    showTooltip: 'deviceIP2.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validationIP],
    required: true
  },
  ipInputDevice: {
    label: 'ipInputDevice.label',
    showTooltip: 'ipInputDevice.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required, validationIP],
    required: true,
    enabledIf: [
      {
        fieldID: 'gridBlockEnable',
        values: [true]
      }
    ]
  },
  kebaChargingType: {
    label: 'chargingType',
    component: Select,
    options: () => [
      { label: i18n.t('alwaysCharge'), value: '0' },
      { label: i18n.t('chargeWithSolarPower'), value: '1' },
      { label: i18n.t('chargeInLowTariff'), value: '2' },
      { label: i18n.t('doNotCharge'), value: '3' }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  legionellaProtection: {
    label: 'legionellaProtection.label',
    component: Select,
    options: () => [
      { label: i18n.t('legionellaProtection.options.0'), value: 0 },
      { label: i18n.t('legionellaProtection.options.1'), value: 1 },
      { label: i18n.t('legionellaProtection.options.2'), value: 2 },
      { label: i18n.t('legionellaProtection.options.3'), value: 3 },
      { label: i18n.t('legionellaProtection.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  legionellaSensor: {
    label: 'legionellaSensor.label',
    showTooltip: 'legionellaSensor.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('legionellaSensor.options.0'), value: 0 },
      { label: i18n.t('legionellaSensor.options.1'), value: 1 },
      { label: i18n.t('legionellaSensor.options.2'), value: 2 },
      { label: i18n.t('legionellaSensor.options.3'), value: 3 },
      { label: i18n.t('legionellaSensor.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  lowTariffEndHourMin: {
    label: 'lowTariffEndHourMin.label',
    showTooltip: 'lowTariffEndHourMin.tooltip',
    component: TimePickerInput,
    parse: timeParser,
    className: 'm-input',
    validate: [time],
    enabledIf: [
      {
        fieldID: 'enableLowTariff',
        values: [true]
      }
    ]
  },
  lowTarriffStartHourMin: {
    label: 'lowTarriffStartHourMin.label',
    showTooltip: 'lowTarriffStartHourMin.tooltip',
    component: TimePickerInput,
    parse: timeParser,
    className: 'm-input',
    validate: [time],
    enabledIf: [
      {
        fieldID: 'enableLowTariff',
        values: [true]
      }
    ]
  },
  reducedCurrentEnd: {
    label: 'reducedCurrentEnd.label',
    showTooltip: 'reducedCurrentEnd.tooltip',
    component: TimePickerInput,
    parse: timeParser,
    className: 'm-input',
    validate: [required, time],
    required: true,
    enabledIf: [
      {
        fieldID: 'enableReducedCurrent',
        values: [true]
      }
    ]
  },
  reducedCurrentStart: {
    label: 'reducedCurrentStart.label',
    showTooltip: 'reducedCurrentStart.tooltip',
    component: TimePickerInput,
    parse: timeParser,
    className: 'm-input',
    validate: [required, time],
    required: true,
    enabledIf: [
      {
        fieldID: 'enableReducedCurrent',
        values: [true]
      }
    ]
  },
  maxDischargePower: {
    label: 'maxDischargePower.label',
    showTooltip: 'maxDischargePower.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  maxChargePower: {
    label: 'maxChargePower.label',
    showTooltip: 'maxChargePower.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  maxChargeDischargePower: {
    label: 'maxChargeDischargePower.label',
    showTooltip: 'maxChargeDischargePower.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput
  },
  maximalOnDurationPerDay: {
    label: 'maximalOnDurationPerDay.label',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  maximalOnDurationPerDayTime: {
    label: 'maximalOnDurationPerDayTime.label',
    showTooltip: 'maximalOnDurationPerDayTime.tooltip',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'maximalOnDurationPerDay',
        values: [true]
      }
    ]
  },
  maximalSocAvailable: {
    label: 'maximalSocAvailable.label',
    showTooltip: 'maximalSocAvailable.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100, validateMinimalSocLessThanMaximalSoc],
    required: true
  },
  maxPower: {
    label: 'maxPower',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  maxPowerConsumption: {
    label: 'maxPowerConsumption.label',
    showTooltip: 'maxPowerConsumption.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    parse: parseOnlyPositiveNumbers,
    required: true,
    enabledIf: [
      {
        ignore: ['Ochsner Europe', 'NIBE SMO40', 'Weider', 'Samsung Heatpump', 'Simulator Water Heater'],
        fieldID: 'inverterHeatPump',
        values: [true]
      }
    ]
  },
  maximumCurrent: {
    label: 'maximumCurrent.label',
    showTooltip: 'maximumCurrent.tooltip',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    parse: parseOnlyPositiveIntegerNumbers,
    validate: [required],
    required: true
  },
  maxCurrent: {
    label: 'maxCurrent.label',
    showTooltip: 'maxCurrent.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('maxCurrent.options.0'), value: 0 },
      { label: i18n.t('maxCurrent.options.1'), value: 1 },
      { label: i18n.t('maxCurrent.options.2'), value: 2 },
      { label: i18n.t('maxCurrent.options.3'), value: 3 },
      { label: i18n.t('maxCurrent.options.4'), value: 4 },
      { label: i18n.t('maxCurrent.options.5'), value: 5 },
      { label: i18n.t('maxCurrent.options.6'), value: 6 },
      { label: i18n.t('maxCurrent.options.7'), value: 7 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  minimalChargingDuration: {
    label: 'minimalChargingDuration.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'minimalChargingDuration.tooltip'
  },
  minimalOnDuration: {
    label: 'minimalOnDuration.label',
    showTooltip: 'minimalOnDuration.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    disabledFor: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      roles: ['end_user', 'root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES, 'property_management', 'oem', 'oem_employee', 'oem_employee_read_only', 'viewer']
    }
  },
  minimalOnDurationPerDay: {
    label: 'minimalOnDurationPerDay.label',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  minimalOnDurationPerDayTime: {
    label: 'minimalOnDurationPerDayTime.label',
    showTooltip: 'minimalOnDurationPerDayTime.tooltip',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'minimalOnDurationPerDay',
        values: [true]
      }
    ]
  },
  minimalOnDurationPerDayStart: {
    label: 'minimalOnDurationPerDayStart.label',
    showTooltip: 'minimalOnDurationPerDayStart.tooltip',
    component: TimePickerInput,
    parse: timeParser,
    className: 'm-input',
    validate: [time],
    enabledIf: [
      {
        fieldID: 'minimalOnDurationPerDay',
        values: [true]
      }
    ]
  },
  minPower: {
    label: 'minPower.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parsePositiveIntegerOrZero,
    showTooltip: 'minPower.tooltip',
    disabledFor: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      roles: ['end_user']
    }
  },
  minimumChargeQuantityTargetAmount: {
    label: 'minimumChargeQuantityTargetAmount.label',
    showTooltip: 'minimumChargeQuantityTargetAmount.tooltip',
    component: CustomTextInput,
    defaultValue: 20,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from1to100],
    enabledIf: [
      {
        fieldID: 'chargingMode',
        values: [6]
      }
    ],
    switchAction: {
      defaultValue: 20,
      checkIfEnabled: (deviceType, switchingMode) => ['Car Charging', 'V2X'].includes(deviceType) && switchingMode === 6
    }
  },
  minimumChargeQuantityTargetDateTime: {
    label: 'minimumChargeQuantityTargetDateTime.label',
    showTooltip: 'minimumChargeQuantityTargetDateTime.tooltip',
    component: DateTimePicker,
    enabledIf: [
      {
        fieldID: 'chargingMode',
        values: [6]
      }
    ]
  },

  minPower2: {
    label: 'minPower2.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parsePositiveIntegerOrZero,
    showTooltip: 'minPower2.tooltip'
  },
  minimalSocAvailable: {
    label: 'minimalSocAvailable.label',
    showTooltip: 'minimalSocAvailable.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100],
    required: true
  },
  NetworkKeepAliveCheckIntevalTime: {
    label: 'NetworkKeepAliveCheckIntevalTime',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  notes: {
    label: 'notes.label',
    showTooltip: 'notes.tooltip',
    component: CustomTextField,
    className: 'note-field'
  },
  deviceIdentification: {
    label: 'deviceIdentification.label',
    showTooltip: 'deviceIdentification.tooltip',
    component: CustomTextInput,
    className: 'note-field'
  },
  registerNumber1: {
    label: 'registerNumber1.label',
    showTooltip: 'registerNumber1.tooltip',
    component: CustomTextInput,
    className: 'note-field',
    required: true,
    validate: [required]
  },
  registerNumber2: {
    label: 'registerNumber2.label',
    showTooltip: 'registerNumber2.tooltip',
    component: CustomTextInput,
    className: 'note-field',
    required: true,
    validate: [required]
  },
  oldIdRtu: {
    label: 'oldIdRtu.label',
    parse: parsePositiveAndZeroNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'setIdRtu',
        values: [true]
      }
    ]
  },
  ohmpilotMode: {
    label: 'ohmpilotMode.label',
    showTooltip: 'ohmpilotMode.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('ohmpilotMode.options.0'), value: 0 },
      { label: i18n.t('ohmpilotMode.options.1'), value: 1 },
      { label: i18n.t('ohmpilotMode.options.2'), value: 2 },
      { label: i18n.t('ohmpilotMode.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  offsetMinHeatingCircuitDirect: {
    label: 'offsetMinHeatingCircuitDirect.label',
    showTooltip: 'offsetMinHeatingCircuitDirect.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuitDirectEnable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuitDirect: {
    label: 'offsetMaxHeatingCircuitDirect.label',
    showTooltip: 'offsetMaxHeatingCircuitDirect.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuitDirectEnable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit1: {
    label: 'offsetMinHeatingCircuit1.label',
    showTooltip: 'offsetMinHeatingCircuit1.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseIntegerNumbers,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit1Enable',
        values: [true]
      }
    ]
  },
  offsetNormalHeatingCircuit1: {
    label: 'offsetNormalHeatingCircuit1.label',
    showTooltip: 'offsetNormalHeatingCircuit1.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseIntegerNumbers,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit1Enable',
        values: [true]
      }
    ]
  },
  offsetNormalHeatingCircuit2: {
    label: 'offsetNormalHeatingCircuit2.label',
    showTooltip: 'offsetNormalHeatingCircuit2.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseIntegerNumbers,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit2Enable',
        values: [true]
      }
    ]
  },
  offsetNormalHeatingCircuit3: {
    label: 'offsetNormalHeatingCircuit3.label',
    showTooltip: 'offsetNormalHeatingCircuit3.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit3Enable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit2: {
    label: 'offsetMinHeatingCircuit2.label',
    showTooltip: 'offsetMinHeatingCircuit2.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit2Enable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit3: {
    label: 'offsetMinHeatingCircuit3.label',
    showTooltip: 'offsetMinHeatingCircuit3.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit3Enable',
        values: [true]
      }
    ]
  },
  offsetMinHeatingCircuit4: {
    label: 'offsetMinHeatingCircuit4.label',
    showTooltip: 'offsetMinHeatingCircuit4.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit4Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit1: {
    label: 'offsetMaxHeatingCircuit1.label',
    showTooltip: 'offsetMaxHeatingCircuit1.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, onlyZeroAndHigherPositives],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit1Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit2: {
    label: 'offsetMaxHeatingCircuit2.label',
    showTooltip: 'offsetMaxHeatingCircuit2.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, onlyZeroAndHigherPositives],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit2Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit3: {
    label: 'offsetMaxHeatingCircuit3.label',
    showTooltip: 'offsetMaxHeatingCircuit3.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit3Enable',
        values: [true]
      }
    ]
  },
  offsetMaxHeatingCircuit4: {
    label: 'offsetMaxHeatingCircuit4.label',
    showTooltip: 'offsetMaxHeatingCircuit4.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput,
    validate: [required, betweenPlusMinus2],
    required: true,
    enabledIf: [
      {
        fieldID: 'heatingCircuit4Enable',
        values: [true]
      }
    ]
  },
  outputChannel: {
    label: 'outputChannel.label',
    showTooltip: 'outputChannel.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('outputChannel.options.0'), value: 0 },
      { label: i18n.t('outputChannel.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  OcppUrl: {
    label: 'OcppUrl.label',
    className: 'note-field',
    component: OcppUrlField,
    validate: []
  },
  OcppPaymentUrl: {
    label: 'OcppPaymentUrl.label',
    showTooltip: 'OcppPaymentUrl.tooltip',
    className: 'm-input OcppPaymentUrlField',
    component: CustomTextInput,
    validate: []
  },
  OCPPChargerID: {
    label: 'OCPPChargerID.label',
    showTooltip: 'OCPPChargerID.tooltip',
    className: 'm-input',
    component: SearchChargersField,
    validate: [required],
    required: true
  },
  Password: {
    label: 'pass',
    component: CustomTextInput,
    type: 'password',
    className: 'm-input',
    validate: [required],
    required: true
  },
  phaseSwitch: {
    label: 'phaseSwitch.label',
    showTooltip: 'phaseSwitch.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('phaseSwitch.options.0'), value: 0 },
      { label: i18n.t('phaseSwitch.options.1'), value: 1 },
      { label: i18n.t('phaseSwitch.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  phaseSwitchKeba: {
    label: 'phaseSwitchKeba.label',
    showTooltip: 'phaseSwitchKeba.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('phaseSwitchKeba.options.0'), value: 0 },
      { label: i18n.t('phaseSwitchKeba.options.1'), value: 1 },
      { label: i18n.t('phaseSwitchKeba.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  port: {
    label: 'port.label',
    showTooltip: 'port.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validatePort],
    normalize: parseStrictPositiveNumbers,
    required: true
  },
  poolHeating: {
    label: 'poolHeating.label',
    showTooltip: 'poolHeating.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  poolHeatingChoice: {
    label: 'poolHeatingChoice.label',
    showTooltip: 'poolHeatingChoice.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('poolHeatingChoice.options.0'), value: 0 },
      { label: i18n.t('poolHeatingChoice.options.1'), value: 1 },
      { label: i18n.t('poolHeatingChoice.options.2'), value: 2 },
      { label: i18n.t('poolHeatingChoice.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  poolOffset: {
    label: 'poolOffset.label',
    showTooltip: 'poolOffset.tooltip',
    className: 'm-input',
    parse: parseOnlyPositiveNumbers,
    component: CustomTextInput,
    validate: [required, from0to100],
    required: true,
    enabledIf: [
      {
        fieldID: 'poolHeating',
        values: [true]
      }
    ]
  },
  powerEstimation: {
    label: 'powerEstimation.label',
    showTooltip: 'powerEstimation.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  productID: {
    label: 'productID.label',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseStringOfLettersAndNumbers,
    validate: [required, minLen3, maxLen50],
    required: true
  },
  roomTempNormalHk2: {
    label: 'roomTempNormalHk2.label',
    showTooltip: 'roomTempNormalHk2.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat, ctaRtuFrom20to55Validation],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  reducedCurrent: {
    label: 'reducedCurrent.label',
    showTooltip: 'reducedCurrent.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'enableReducedCurrent',
        values: [true]
      }
    ]
  },
  relaisChooseSet: {
    label: 'relaisChooseSet.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisChooseSet.options.1'), value: 1 },
      { label: i18n.t('relaisChooseSet.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisChooseSet2: {
    label: 'relaisChooseSet2.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisChooseSet2.options.1'), value: 1 },
      { label: i18n.t('relaisChooseSet2.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber: {
    label: 'relaisNumber.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber.options.1'), value: 1 },
      { label: i18n.t('relaisNumber.options.2'), value: 2 },
      { label: i18n.t('relaisNumber.options.3'), value: 3 },
      { label: i18n.t('relaisNumber.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber2: {
    label: 'relaisNumber2.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber2.options.1'), value: 1 },
      { label: i18n.t('relaisNumber2.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber3: {
    label: 'relaisNumber3.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber3.options.1'), value: 1 },
      { label: i18n.t('relaisNumber3.options.2'), value: 2 },
      { label: i18n.t('relaisNumber3.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber4: {
    label: 'relaisNumber4.label',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber4.options.1'), value: 1 },
      { label: i18n.t('relaisNumber4.options.2'), value: 2 },
      { label: i18n.t('relaisNumber4.options.3'), value: 3 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber2Input: {
    label: 'relaisNumber2Input.label',
    showTooltip: 'relaisNumber2Input.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber2Input.options.1'), value: 1 },
      { label: i18n.t('relaisNumber2Input.options.2'), value: 2 },
      { label: i18n.t('relaisNumber2Input.options.3'), value: 3 },
      { label: i18n.t('relaisNumber2Input.options.4'), value: 4 },
      { label: i18n.t('relaisNumber2Input.options.5'), value: 5 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber3Input: {
    label: 'relaisNumber3Input.label',
    showTooltip: 'relaisNumber3Input.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber3Input.options.1'), value: 1 },
      { label: i18n.t('relaisNumber3Input.options.2'), value: 2 },
      { label: i18n.t('relaisNumber3Input.options.3'), value: 3 },
      { label: i18n.t('relaisNumber3Input.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber4Input: {
    label: 'relaisNumber4Input.label',
    showTooltip: 'relaisNumber4Input.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber4Input.options.1'), value: 1 },
      { label: i18n.t('relaisNumber4Input.options.2'), value: 2 },
      { label: i18n.t('relaisNumber4Input.options.3'), value: 3 },
      { label: i18n.t('relaisNumber4Input.options.4'), value: 4 },
      { label: i18n.t('relaisNumber4Input.options.5'), value: 5 },
      { label: i18n.t('relaisNumber4Input.options.6'), value: 6 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  relaisNumber8: {
    label: 'relaisNumber8.label',
    showTooltip: 'relaisNumber8.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('relaisNumber8.options.1'), value: 1 },
      { label: i18n.t('relaisNumber8.options.2'), value: 2 },
      { label: i18n.t('relaisNumber8.options.3'), value: 3 },
      { label: i18n.t('relaisNumber8.options.4'), value: 4 },
      { label: i18n.t('relaisNumber8.options.5'), value: 5 },
      { label: i18n.t('relaisNumber8.options.6'), value: 6 },
      { label: i18n.t('relaisNumber8.options.7'), value: 7 },
      { label: i18n.t('relaisNumber8.options.8'), value: 8 },
      { label: i18n.t('relaisNumber8.options.9'), value: 9 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  roomTempOverproductionEnableHk2: {
    label: 'roomTempOverproductionEnableHk2.label',
    showTooltip: 'roomTempOverproductionEnableHk2.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  roomTempOverproductionEnable: {
    label: 'roomTempOverproductionEnable.label',
    showTooltip: 'roomTempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  roomTempOverproduction: {
    label: 'roomTempOverproduction.label',
    showTooltip: 'roomTempOverproduction.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat, ctaRtuFrom20to55Validation],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  roomTempNormal: {
    label: 'roomTempNormal.label',
    showTooltip: 'roomTempNormal.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat, ctaRtuFrom20to55Validation],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  roomTempReduced: {
    label: 'roomTempReduced.label',
    showTooltip: 'roomTempReduced.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat, ctaRtuFrom20to55Validation],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnable',
        values: [true]
      }
    ]
  },
  roomTempOverproductionHk2: {
    label: 'roomTempOverproductionHk2.label',
    showTooltip: 'roomTempOverproductionHk2.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat, ctaRtuFrom20to55Validation],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  roomTempReducedHk2: {
    label: 'roomTempReducedHk2.label',
    showTooltip: 'roomTempReducedHk2.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat, ctaRtuFrom20to55Validation],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk2',
        values: [true]
      }
    ]
  },
  roomTempOverproductionEnableHk3: {
    label: 'roomTempOverproductionEnableHk3.label',
    showTooltip: 'roomTempOverproductionEnableHk3.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  roomTempOverproductionHk3: {
    label: 'roomTempOverproductionHk3.label',
    showTooltip: 'roomTempOverproductionHk3.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  roomTempNormalHk3: {
    label: 'roomTempNormalHk3.label',
    showTooltip: 'roomTempNormalHk3.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  roomTempReducedHk3: {
    label: 'roomTempReducedHk3.label',
    showTooltip: 'roomTempReducedHk3.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'roomTempOverproductionEnableHk3',
        values: [true]
      }
    ]
  },
  rTUOrTcp: {
    label: 'rTUOrTcp.label',
    showTooltip: 'rTUOrTcp.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('rTUOrTcp.options.0'), value: 0 },
      { label: i18n.t('rTUOrTcp.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  serialnumberMyPv: {
    label: 'serialnumberMyPv.label',
    component: CustomTextInput,
    className: 'm-input',
    parse: parsePositiveNumbersOrNothing,
    showTooltip: 'serialnumberMyPv.tooltip'
  },
  SerialNumber: {
    label: 'serialNumber',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  SerialNumberSmartMeter: {
    label: 'SerialNumberSmartMeter.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  setIdRtu: {
    label: 'setIdRtu.label',
    showTooltip: 'setIdRtu.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  secret: {
    label: 'secret.label',
    showTooltip: 'secret.tooltip',
    type: 'password',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  tokenString: {
    label: 'tokenString.label',
    showTooltip: 'tokenString.tooltip',
    component: TokenString,
    className: 'm-input',
    validate: [required],
    required: true
  },
  tokenGeneral: {
    label: 'tokenGeneral.label',
    showTooltip: 'tokenGeneral.tooltip',
    component: CustomTextInputHoc,
    className: 'm-input',
    validate: [required],
    required: true
  },
  tokenOptional: {
    label: 'tokenGeneral.label',
    showTooltip: 'tokenGeneral.tooltip',
    component: CustomTextInputHoc,
    className: 'm-input',
    validate: [required],
    required: true
  },
  tokenStringHC: {
    label: 'tokenStringHC.label',
    showTooltip: 'tokenStringHC.tooltip',
    component: TokenString,
    className: 'm-input',
    validate: [required],
    required: true
  },
  sgReadyStiebel: {
    label: 'sgReadyStiebel.label',
    showTooltip: 'sgReadyStiebel.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  SmartMeterPosition: {
    label: 'SmartMeterPosition.label',
    component: Select,
    options: () => [
      { label: i18n.t('SmartMeterPosition.options.0'), value: '0' },
      { label: i18n.t('SmartMeterPosition.options.1'), value: '1' }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  smartGridreadyXml: {
    label: 'smartGridreadyXml.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  smartPlugChargingType: {
    label: 'smartPlugChargingType.label',
    showTooltip: 'smartPlugChargingType.tooltip',
    component: Select,
    options: ({ license, userApp }) => {
      const options = [
        { label: i18n.t('smartPlugChargingType.options.1'), value: 1 },
        { label: i18n.t('smartPlugChargingType.options.2'), value: 2 },
        { label: i18n.t('smartPlugChargingType.options.3'), value: 3 },
        { label: i18n.t('smartPlugChargingType.options.4'), value: 4 },
        { label: i18n.t('smartPlugChargingType.options.5'), value: 5 }
      ];

      if (userApp === 'thermondoEndUserApp') {
        if (license?.restrictions?.allowedModes?.smartPlugChargingType) {
          return getOptionsWithRestrictions(options, license.restrictions.allowedModes.smartPlugChargingType, license.type);
        }
      }

      return options;
    },
    className: 'm-input',
    validate: [required],
    required: true
  },
  solarOptimized: {
    label: 'solarOptimized.label',
    showTooltip: 'solarOptimized.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  storageMedium: {
    label: 'storageMedium.label',
    showTooltip: 'storageMedium.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('storageMedium.options.0'), value: 0 },
      { label: i18n.t('storageMedium.options.1'), value: 1 },
      { label: i18n.t('storageMedium.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  storageVolume: {
    label: 'storageVolume.label',
    showTooltip: 'storageVolume.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from100to9000],
    required: true
  },
  chargingTimes: {
    label: 'chargingTimes.label',
    showTooltip: 'chargingTimes.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('chargingTimes.options.1'), value: 1 },
      { label: i18n.t('chargingTimes.options.2'), value: 2 },
      { label: i18n.t('chargingTimes.options.3'), value: 3 },
      { label: i18n.t('chargingTimes.options.4'), value: 4 },
      { label: i18n.t('chargingTimes.options.5'), value: 5 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  switchOffDelay: {
    label: 'switchOffDelay.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required, toBeOneOrPositive],
    required: true,
    parse: parseOnlyPositiveIntegerNumbers,
    showTooltip: 'switchOffDelay.tooltip',
    disabledFor: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      roles: ['end_user', 'root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES, 'property_management', 'oem', 'viewer', 'oem_employee', 'oem_employee_read_only']
    }
  },
  switchOnDelay: {
    label: 'switchOnDelay.label',
    component: CustomTextInputHoc,
    isInteger: true,
    className: 'm-input',
    validate: [required, toBeOneOrPositive],
    required: true,
    parse: parseOnlyPositiveIntegerNumbers,
    showTooltip: 'switchOnDelay.tooltip',
    disabledFor: {
      deviceName: ['CTA heat pump', 'CTA heat pump RTU', 'CTA carel'],
      roles: ['end_user', 'root', 'solar_admin', 'pv_installer', ...EMPLOYEE_ROLE_TYPES, 'property_management', 'oem', 'viewer', 'oem_employee', 'oem_employee_read_only']
    }
  },
  switchingTimesEnable: {
    label: 'switchingTimesEnable.label',
    showTooltip: 'switchingTimesEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  switchingTimes: {
    component: SwitchingTimes,
    validate: [
      (arr = []) => {
        const errors = arr.map(({ from, to }) => validateFromLessOrEqualToTo(from, to));
        return errors.some(Boolean) ? errors : undefined;
      }
    ],
    enabledIf: [
      {
        fieldID: 'switchingTimesEnable',
        values: [true]
      }
    ],
    expectedFields: [
      'from',
      'to',
      'activeDays',
      'switchMode',
      'switchAction',
      'minimumChargeQuantityTargetAmount',
      'constantCurrentSetting',
      'intelligentStartTime',
      'socLowerLimit',
      'socUpperLimit',
      'socLimit',
      'batteryPower',
      'chargingTargetSoc',
      'powerSettingPercent',
      'batteryManualMode',
      'lowerSocLimit',
      'upperSocLimit',
      'dischargeSocLimit',
      'morningSocLimit',
      'chargingSocLimit',
      'peakShavingSocDischargeLimit',
      'peakShavingSocMaxLimit',
      'peakShavingMaxGridPower',
      'peakShavingRechargePower',
      'tariffPriceLimit',
      'tariffPriceLimitSocMax',
      'tariffPriceLimitForecast',
      'powerCharge',
      'powerDischarge',
      'standardStandaloneAllowed',
      'standardLowerSocLimit',
      'standardUpperSocLimit'
    ]
  },
  from: {
    label: i18n.t('fromDateLabel'),
    component: TimePickerInput,
    className: 'm-input opened__time-picker',
    placeholder: 'selectTime',
    shrink: true,
    errorPosition: 'relative',
    validate: [required],
    parse: timeParser
  },
  to: {
    label: i18n.t('toDateLabel'),
    component: TimePickerInput,
    className: 'm-input opened__time-picker',
    placeholder: 'selectTime',
    shrink: true,
    errorPosition: 'relative',
    validate: [required],
    parse: timeParser
  },
  activeDays: {
    label: 'selectDays',
    component: SelectDays,
    placeholder: i18n.t('selectAllSwitchingDays'),
    validate: [requiredDays]
  },
  switchAction: {
    component: Select,
    label: 'switchAction.label',
    placeholder: 'selectAction',
    validate: [required],
    options: () => [
      { label: i18n.t('switchAction.options.0'), value: 0 },
      { label: i18n.t('switchAction.options.1'), value: 1 }
    ],
    switchAction: {
      checkIfEnabled: (deviceType) => !['Appliances', 'Car Charging', 'V2X', 'Switches', 'Smart Plug', 'Battery', 'Heatpump', 'Water Heater'].includes(deviceType)
    }
  },
  switchMode: {
    component: Select,
    placeholder: 'selectAction',
    validate: [required],
    switchAction: {
      checkIfEnabled: (deviceType) => ['Appliances', 'Car Charging', 'V2X', 'Switches', 'Smart Plug', 'Battery', 'Heatpump', 'Water Heater'].includes(deviceType)
    },
    options: ({ deviceType, waterHeaterType, mode, isCarExists, isShow, license, userApp, allowedBatteryModeOptions, tariffType }) => {
      switch (deviceType) {
        case 'Car Charging': {
          const options = [
            { label: i18n.t('chargingMode.options.0'), value: 0 },
            { label: i18n.t('chargingMode.options.1'), value: 1 },
            { label: i18n.t('chargingMode.options.2'), value: 2 },
            { label: i18n.t('chargingMode.options.5'), value: 5 },
            { label: i18n.t('chargingMode.options.6'), value: 6 },
            { label: i18n.t('chargingMode.options.3'), value: 3 },
            { label: i18n.t('chargingMode.options.4'), value: 4 }
          ];

          if ((isShow || isCarExists || mode === 'add') && !license?.restrictions?.isCarRestricted) {
            options.push({ label: i18n.t('chargingMode.options.7'), value: 7 });
          }

          if (userApp === 'thermondoEndUserApp') {
            if (license?.restrictions?.allowedModes?.chargingMode) {
              return getOptionsWithRestrictions(options, license.restrictions.allowedModes.chargingMode, license.type);
            }
          }

          return options;
        }
        case 'V2X': {
          const options = [
            { label: i18n.t('chargingMode.options.0'), value: 0 },
            { label: i18n.t('chargingMode.options.1'), value: 1 },
            { label: i18n.t('chargingMode.options.2'), value: 2 },
            { label: i18n.t('chargingMode.options.5'), value: 5 },
            { label: i18n.t('chargingMode.options.6'), value: 6 },
            { label: i18n.t('chargingMode.options.3'), value: 3 },
            { label: i18n.t('chargingMode.options.4'), value: 4 }
          ];

          if (userApp === 'thermondoEndUserApp') {
            if (license?.restrictions?.allowedModes?.chargingMode) {
              return getOptionsWithRestrictions(options, license.restrictions.allowedModes.chargingMode, license.type);
            }
          }

          return options;
        }
        case 'Heatpump': {
          const options = [
            { label: i18n.t('heatPumpChargingMode.options.1'), value: 1 },
            { label: i18n.t('heatPumpChargingMode.options.2'), value: 2 },
            { label: i18n.t('heatPumpChargingMode.options.3'), value: 3 },
            { label: i18n.t('heatPumpChargingMode.options.4'), value: 4 },
            { label: i18n.t('heatPumpChargingMode.options.5'), value: 5 }
          ];

          if (userApp === 'thermondoEndUserApp') {
            const thermondoOptions = [
              { label: i18n.t('heatPumpChargingMode.options.3'), value: 3 },
              { label: i18n.t('heatPumpChargingMode.options.6'), value: 6 },
              { label: i18n.t('heatPumpChargingMode.options.7'), value: 7 }
            ];

            if (license?.restrictions?.allowedModes?.heatPumpChargingMode) {
              return getOptionsWithRestrictions(thermondoOptions, license.restrictions.allowedModes.heatPumpChargingMode, license.type);
            }

            return thermondoOptions;
          }

          return options;
        }
        case 'Appliances': {
          const options = [
            { label: i18n.t('homeApplianceChargingMode.options.1'), value: 1 },
            { label: i18n.t('homeApplianceChargingMode.options.2'), value: 2 },
            { label: i18n.t('homeApplianceChargingMode.options.3'), value: 3 },
            { label: i18n.t('homeApplianceChargingMode.options.4'), value: 4 }
          ];

          if (license?.restrictions?.allowedModes?.homeApplianceChargingMode) {
            return getOptionsWithRestrictions(options, license.restrictions.allowedModes.homeApplianceChargingMode, license.type);
          }

          return options;
        }
        case 'Battery': {
          if (allowedBatteryModeOptions?.length) {
            const options = [
              { label: i18n.t('batteryMode.options.0'), value: 0 },
              { label: i18n.t('batteryMode.options.5'), value: 5 },
              { label: i18n.t('batteryMode.options.1'), value: 1 },
              { label: i18n.t('batteryMode.options.2'), value: 2 },
              { label: i18n.t('batteryMode.options.3'), value: 3 },
              { label: i18n.t('batteryMode.options.4'), value: 4 }
            ].filter(({ value }) => allowedBatteryModeOptions?.includes(value)) // filtering battery modes based on device doc
              .filter(({ value }) => (tariffType !== 'dynamic' ? ![4].includes(value) : true)); // filtering battery modes based on tariff type

            if (license?.restrictions?.allowedModes?.batteryMode) {
              return getOptionsWithRestrictions(options, license.restrictions.allowedModes.batteryMode); // filtering battery modes based on license restrictions
            }

            return options;
          }

          return [
            { label: i18n.t('batteryChargingMode.options.0'), value: 0 },
            { label: i18n.t('batteryChargingMode.options.1'), value: 1 },
            { label: i18n.t('batteryChargingMode.options.2'), value: 2 },
            { label: i18n.t('batteryChargingMode.options.3'), value: 3 },
            { label: i18n.t('batteryChargingMode.options.4'), value: 4 }
          ];
        }
        case 'Switches':
          return [
            { label: i18n.t('chargingTimes.options.1'), value: 1 },
            { label: i18n.t('chargingTimes.options.2'), value: 2 },
            { label: i18n.t('chargingTimes.options.3'), value: 3 },
            { label: i18n.t('chargingTimes.options.4'), value: 4 },
            { label: i18n.t('chargingTimes.options.5'), value: 5 }
          ];
        case 'Smart Plug': {
          const options = [
            { label: i18n.t('smartPlugChargingType.options.1'), value: 1 },
            { label: i18n.t('smartPlugChargingType.options.2'), value: 2 },
            { label: i18n.t('smartPlugChargingType.options.3'), value: 3 },
            { label: i18n.t('smartPlugChargingType.options.4'), value: 4 },
            { label: i18n.t('smartPlugChargingType.options.5'), value: 5 }
          ];

          if (userApp === 'thermondoEndUserApp') {
            if (license?.restrictions?.allowedModes?.smartPlugChargingType) {
              return getOptionsWithRestrictions(options, license.restrictions.allowedModes.smartPlugChargingType, license.type);
            }
          }

          return options;
        }
        case 'Water Heater': {
          const options = [
            { label: i18n.t('waterHeaterMode.options.1'), value: 1 },
            { label: i18n.t('waterHeaterMode.options.2'), value: 2 },
            { label: i18n.t('waterHeaterMode.options.3'), value: 3 },
            { label: i18n.t('waterHeaterMode.options.4'), value: 4 },
            { label: i18n.t('waterHeaterMode.options.5'), value: 5 }
          ];

          if (waterHeaterType === 'HP' || isShow) {
            options.push({ label: i18n.t('waterHeaterMode.options.6'), value: 6 });
          }

          if (userApp === 'thermondoEndUserApp') {
            if (license?.restrictions?.allowedModes?.waterHeaterMode) {
              return getOptionsWithRestrictions(options, license.restrictions.allowedModes.waterHeaterMode, license.type);
            }
          }

          return options;
        }

        default:
          return [
            { label: i18n.t('off'), value: 0 },
            { label: i18n.t('on'), value: 1 }
          ];
      }
    }
  },
  switchDelay: {
    label: 'switchDelay.label',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyPositiveNumbers,
    showTooltip: 'switchDelay.tooltip'
  },
  targetTemp: {
    label: 'targetTemp.label',
    showTooltip: 'targetTemp.tooltip',
    parse: parseIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    validate: [required],
    required: true
  },
  targetTempLowTariff: {
    label: 'targetTempLowTariff.label',
    showTooltip: 'targetTempLowTariff.tooltip',
    parse: parseIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true,
    enabledIf: [
      {
        fieldID: 'enableLowTariff',
        values: [true]
      }
    ]
  },
  tempControl: {
    label: 'tempControl.label',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  tempControlLimit: {
    label: 'tempControlLimit.label',
    showTooltip: 'tempControlLimit.tooltip',
    parse: parseOnlyPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'tempControl',
        values: [true]
      }
    ]
  },
  tokenEQmatic: {
    label: 'tokenEQmatic.label',
    showTooltip: 'tokenEQmatic.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  temperatureVisualizationChoice: {
    label: 'temperatureVisualizationChoice.label',
    showTooltip: 'temperatureVisualizationChoice.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('temperatureVisualizationChoice.options.0'), value: 0 },
      { label: i18n.t('temperatureVisualizationChoice.options.1'), value: 1 },
      { label: i18n.t('temperatureVisualizationChoice.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  thermalDisinfectionEnable: {
    label: 'thermalDisinfectionEnable.label',
    showTooltip: 'thermalDisinfectionEnable.tooltip',
    className: 'm-input',
    parse: parseBoolean,
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  smartCooling: {
    label: 'smartCooling.label',
    showTooltip: 'smartCooling.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  coolingWaterTemperature: {
    label: 'coolingWaterTemperature.label',
    showTooltip: 'coolingWaterTemperature.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    enabledIf: [
      {
        fieldID: 'smartCooling',
        values: [true]
      }
    ]
  },
  UDPPort: {
    label: 'UDPPort',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  updateRequestIntervalTime: {
    label: 'updateRequestIntervalTime',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true,
    parse: parseOnlyNumbers
  },
  unlocked: {
    label: 'unlocked.label',
    showTooltip: 'unlocked.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  User: {
    label: 'userName',
    component: CustomTextInput,
    className: 'm-input',
    validate: [required],
    required: true
  },
  callbackUrl: {
    label: 'callbackUrl.label',
    showTooltip: 'callbackUrl.tooltip',
    component: CallbackURL,
    className: 'm-input',
    validate: [required],
    required: true
  },
  waterTempOverproduction: {
    label: 'waterTempOverproduction.label',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  waterTempNormal: {
    label: 'waterTempNormal.label',
    parse: parseStrictPositiveNumbers,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true
  },
  warmWaterDevices: {
    label: 'warmWaterDevices.label',
    showTooltip: 'warmWaterDevices.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('warmWaterDevices.options.0'), value: 0 },
      { label: i18n.t('warmWaterDevices.options.1'), value: 1 },
      { label: i18n.t('warmWaterDevices.options.2'), value: 2 },
      { label: i18n.t('warmWaterDevices.options.3'), value: 3 },
      { label: i18n.t('warmWaterDevices.options.4'), value: 4 },
      { label: i18n.t('warmWaterDevices.options.5'), value: 5 },
      { label: i18n.t('warmWaterDevices.options.6'), value: 6 },
      { label: i18n.t('warmWaterDevices.options.7'), value: 7 },
      { label: i18n.t('warmWaterDevices.options.8'), value: 8 },
      { label: i18n.t('warmWaterDevices.options.9'), value: 9 },
      { label: i18n.t('warmWaterDevices.options.10'), value: 10 },
      { label: i18n.t('warmWaterDevices.options.11'), value: 11 },
      { label: i18n.t('warmWaterDevices.options.12'), value: 12 },
      { label: i18n.t('warmWaterDevices.options.13'), value: 13 },
      { label: i18n.t('warmWaterDevices.options.14'), value: 14 },
      { label: i18n.t('warmWaterDevices.options.15'), value: 15 },
      { label: i18n.t('warmWaterDevices.options.16'), value: 16 },
      { label: i18n.t('warmWaterDevices.options.17'), value: 17 }
    ]
  },
  warmWaterDevices2Step: {
    label: 'warmWaterDevices2Step.label',
    showTooltip: 'warmWaterDevices2Step.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('warmWaterDevices2Step.options.0'), value: 0 },
      { label: i18n.t('warmWaterDevices2Step.options.1'), value: 1 },
      { label: i18n.t('warmWaterDevices2Step.options.2'), value: 2 },
      { label: i18n.t('warmWaterDevices2Step.options.3'), value: 3 },
      { label: i18n.t('warmWaterDevices2Step.options.4'), value: 4 },
      { label: i18n.t('warmWaterDevices2Step.options.5'), value: 5 },
      { label: i18n.t('warmWaterDevices2Step.options.6'), value: 6 },
      { label: i18n.t('warmWaterDevices2Step.options.7'), value: 7 },
      { label: i18n.t('warmWaterDevices2Step.options.8'), value: 8 }
    ]
  },
  warmWaterDevices3Step: {
    label: 'warmWaterDevices3Step.label',
    showTooltip: 'warmWaterDevices3Step.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('warmWaterDevices3Step.options.0'), value: 0 },
      { label: i18n.t('warmWaterDevices3Step.options.1'), value: 1 },
      { label: i18n.t('warmWaterDevices3Step.options.2'), value: 2 },
      { label: i18n.t('warmWaterDevices3Step.options.3'), value: 3 },
      { label: i18n.t('warmWaterDevices3Step.options.4'), value: 4 },
      { label: i18n.t('warmWaterDevices3Step.options.5'), value: 5 }
    ]
  },
  TwoStepSwitchSetting: {
    label: 'TwoStepSwitchSetting.label',
    showTooltip: 'TwoStepSwitchSetting.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('TwoStepSwitchSetting.options.0'), value: 0 },
      { label: i18n.t('TwoStepSwitchSetting.options.1'), value: 1 }
    ]
  },
  heatPumpPVReady: {
    label: 'heatPumpPVReady.label',
    showTooltip: 'heatPumpPVReady.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('heatPumpPVReady.options.0'), value: 0 },
      { label: i18n.t('heatPumpPVReady.options.1'), value: 1 },
      { label: i18n.t('heatPumpPVReady.options.2'), value: 2 },
      { label: i18n.t('heatPumpPVReady.options.3'), value: 3 },
      { label: i18n.t('heatPumpPVReady.options.4'), value: 4 },
      { label: i18n.t('heatPumpPVReady.options.5'), value: 5 },
      { label: i18n.t('heatPumpPVReady.options.6'), value: 6 },
      { label: i18n.t('heatPumpPVReady.options.7'), value: 7 },
      { label: i18n.t('heatPumpPVReady.options.8'), value: 8 },
      { label: i18n.t('heatPumpPVReady.options.9'), value: 9 },
      { label: i18n.t('heatPumpPVReady.options.10'), value: 10 },
      { label: i18n.t('heatPumpPVReady.options.11'), value: 11 },
      { label: i18n.t('heatPumpPVReady.options.12'), value: 12 },
      { label: i18n.t('heatPumpPVReady.options.13'), value: 13 },
      { label: i18n.t('heatPumpPVReady.options.14'), value: 14 },
      { label: i18n.t('heatPumpPVReady.options.15'), value: 15 },
      { label: i18n.t('heatPumpPVReady.options.16'), value: 16 },
      { label: i18n.t('heatPumpPVReady.options.17'), value: 17 }
    ]
  },
  heatPumpSGready: {
    label: 'heatPumpSGready.label',
    showTooltip: 'heatPumpSGready.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: () => [
      { label: i18n.t('heatPumpSGready.options.0'), value: 0 },
      { label: i18n.t('heatPumpSGready.options.1'), value: 1 },
      { label: i18n.t('heatPumpSGready.options.2'), value: 2 },
      { label: i18n.t('heatPumpSGready.options.3'), value: 3 },
      { label: i18n.t('heatPumpSGready.options.4'), value: 4 },
      { label: i18n.t('heatPumpSGready.options.5'), value: 5 },
      { label: i18n.t('heatPumpSGready.options.6'), value: 6 },
      { label: i18n.t('heatPumpSGready.options.7'), value: 7 }
    ]
  },
  zone1TempOverproductionEnable: {
    label: 'zone1TempOverproductionEnable.label',
    showTooltip: 'zone1TempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  zone1OverproductionTemp: {
    label: 'zone1OverproductionTemp.label',
    showTooltip: 'zone1OverproductionTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone1TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone1NormalTemp: {
    label: 'zone1NormalTemp.label',
    showTooltip: 'zone1NormalTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone1TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone1ReducedTemp: {
    label: 'zone1ReducedTemp.label',
    showTooltip: 'zone1ReducedTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone1TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone2TempOverproductionEnable: {
    label: 'zone2TempOverproductionEnable.label',
    showTooltip: 'zone2TempOverproductionEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  zone2OverproductionTemp: {
    label: 'zone2OverproductionTemp.label',
    showTooltip: 'zone2OverproductionTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone2TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone2NormalTemp: {
    label: 'zone2NormalTemp.label',
    showTooltip: 'zone2NormalTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone2TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  zone2ReducedTemp: {
    label: 'zone2ReducedTemp.label',
    showTooltip: 'zone2ReducedTemp.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'zone2TempOverproductionEnable',
        values: [true]
      }
    ]
  },
  deviceActivity: {
    label: 'deviceActivity.label',
    showTooltip: 'deviceActivity.tooltip',
    validate: [required],
    required: true,
    component: Select,
    options: ({ isLoadGuardConfigured, dispatch }) => [
      { label: i18n.t('deviceActivity.options.0'), value: 0 },
      { label: i18n.t('deviceActivity.options.1'), value: 1 },
      { label: i18n.t('deviceActivity.options.2'), value: 2 },
      {
        label: i18n.t('deviceActivity.options.3'),
        value: 3,
        disabled: isLoadGuardConfigured,
        onClick: isLoadGuardConfigured
          ? () => dispatch(openModalWindow({
            modalID: EXPERT_MODAL_ID,
            data: {
              translationKey: 'deviceActivityLoadGuardRestriction',
              title: 'warning'
            }
          }))
          : null
      }
    ]
  },
  ctFactor: {
    label: 'ctFactor.label',
    showTooltip: 'ctFactor.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required],
    required: true
  },
  batteryCapacity: {
    label: 'batteryCapacity.label',
    showTooltip: 'batteryCapacity.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true
  },
  intelligentStartTime: {
    label: 'intelligentStartTime.label',
    showTooltip: 'intelligentStartTime.tooltip',
    component: TimePickerInput,
    parse: timeParser,
    className: 'm-input',
    validate: [required, time],
    required: true,
    enabledIf: [
      {
        fieldID: 'homeApplianceChargingMode',
        values: [1]
      }
    ],
    switchAction: {
      defaultValue: '17:00',
      checkIfEnabled: (deviceType, switchingMode) => ['Appliances'].includes(deviceType) && switchingMode === 1
    }
  },
  subMeterCostTypes: {
    label: 'subMeterCostTypes.label',
    showTooltip: 'subMeterCostTypes.tooltip',
    component: Select,
    validate: [required],
    required: true,
    options: () => [
      { label: i18n.t('subMeterCostTypes.options.0'), value: 0 },
      { label: i18n.t('subMeterCostTypes.options.1'), value: 1 },
      { label: i18n.t('subMeterCostTypes.options.2'), value: 2 },
      { label: i18n.t('subMeterCostTypes.options.3'), value: 3 },
      { label: i18n.t('subMeterCostTypes.options.4'), value: 4 }
    ]
  },
  OCPPConnectorID: {
    label: 'OCPPConnectorID.label',
    showTooltip: 'OCPPConnectorID.tooltip',
    className: 'm-input',
    parse: parseIntegerNumbers,
    component: CustomTextInput
  },
  unbalancedLoadAllowed: {
    label: 'unbalancedLoadAllowed.label',
    showTooltip: 'unbalancedLoadAllowed.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  batteryChargingMode: {
    label: 'batteryChargingMode.label',
    showTooltip: 'batteryChargingMode.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('batteryChargingMode.options.0'), value: 0 },
      { label: i18n.t('batteryChargingMode.options.1'), value: 1 },
      { label: i18n.t('batteryChargingMode.options.2'), value: 2 },
      { label: i18n.t('batteryChargingMode.options.3'), value: 3 },
      { label: i18n.t('batteryChargingMode.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  socUpperLimit: {
    label: 'socUpperLimit.label',
    showTooltip: 'socUpperLimit.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100],
    required: true,
    enabledIf: [
      {
        fieldID: 'batteryChargingMode',
        values: [1]
      }
    ],
    switchAction: {
      defaultValue: 95,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 1
    }
  },
  socLowerLimit: {
    label: 'socLowerLimit.label',
    showTooltip: 'socLowerLimit.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100],
    required: true,
    enabledIf: [
      {
        fieldID: 'batteryChargingMode',
        values: [1]
      }
    ],
    switchAction: {
      defaultValue: 15,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 1
    }
  },
  socLimit: {
    label: 'socLimit.label',
    showTooltip: 'socLimit.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100],
    required: true,
    enabledIf: [
      {
        fieldID: 'batteryChargingMode',
        values: [2, 3]
      }
    ],
    switchAction: {
      // eslint-disable-next-line consistent-return
      defaultValue: (switchingMode) => {
        if (switchingMode === 2) return 95;
        if (switchingMode === 3) return 15;
      },
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && (switchingMode === 2 || switchingMode === 3)
    }
  },
  batteryPower: {
    label: 'batteryPower.label',
    showTooltip: 'batteryPower.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyPositiveNumbers,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'batteryChargingMode',
        values: [2, 3]
      }
    ],
    switchAction: {
      defaultValue: 2000,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && (switchingMode === 2 || switchingMode === 3)
    }
  },
  chargingTargetSoc: {
    label: 'chargingTargetSoc.label',
    showTooltip: 'chargingTargetSoc.tooltip',
    component: CustomTextInput,
    className: 'm-input',
    parse: parseOnlyNumbers,
    validate: [required, from0to100],
    required: true,
    enabledIf: [
      {
        fieldID: 'chargingMode',
        values: [7]
      }
    ],
    switchAction: {
      defaultValue: 80,
      checkIfEnabled: (deviceType, switchingMode) => ['Car Charging'].includes(deviceType) && switchingMode === 7
    }
  },
  chargingTargetSocDateTime: {
    label: 'chargingTargetSocDateTime.label',
    showTooltip: 'chargingTargetSocDateTime.tooltip',
    component: DateTimePicker,
    enabledIf: [
      {
        fieldID: 'chargingMode',
        values: [7]
      }
    ]
  },
  waterHeaterMode: {
    label: 'waterHeaterMode.label',
    showTooltip: 'waterHeaterMode.tooltip',
    component: Select,
    options: ({ waterHeaterType, license, userApp }) => {
      const options = [
        { label: i18n.t('waterHeaterMode.options.1'), value: 1 },
        { label: i18n.t('waterHeaterMode.options.2'), value: 2 },
        { label: i18n.t('waterHeaterMode.options.3'), value: 3 },
        { label: i18n.t('waterHeaterMode.options.4'), value: 4 },
        { label: i18n.t('waterHeaterMode.options.5'), value: 5 }
      ];

      if (waterHeaterType === 'HP') {
        options.push({ label: i18n.t('waterHeaterMode.options.6'), value: 6 });
      }

      if (userApp === 'thermondoEndUserApp') {
        if (license?.restrictions?.allowedModes?.waterHeaterMode) {
          return getOptionsWithRestrictions(options, license.restrictions.allowedModes.waterHeaterMode, license.type);
        }
      }

      return options;
    },
    className: 'm-input',
    validate: [required],
    required: true
  },
  heatPumpChargingMode: {
    label: 'heatPumpChargingMode.label',
    showTooltip: 'heatPumpChargingMode.tooltip',
    component: Select,
    options: ({ userApp, license }) => {
      const options = [
        { label: i18n.t('heatPumpChargingMode.options.1'), value: 1 },
        { label: i18n.t('heatPumpChargingMode.options.2'), value: 2 },
        { label: i18n.t('heatPumpChargingMode.options.3'), value: 3 },
        { label: i18n.t('heatPumpChargingMode.options.4'), value: 4 },
        { label: i18n.t('heatPumpChargingMode.options.5'), value: 5 }
      ];

      if (userApp === 'thermondoEndUserApp') {
        const thermondoOptions = [
          { label: i18n.t('heatPumpChargingMode.options.3'), value: 3 },
          { label: i18n.t('heatPumpChargingMode.options.6'), value: 6 },
          { label: i18n.t('heatPumpChargingMode.options.7'), value: 7 }
        ];

        if (license?.restrictions?.allowedModes?.heatPumpChargingMode) {
          return getOptionsWithRestrictions(thermondoOptions, license.restrictions.allowedModes.heatPumpChargingMode, license.type);
        }

        return thermondoOptions;
      }

      return options;
    },
    className: 'm-input',
    validate: [required],
    required: true
  },
  heatingElementPower: {
    label: 'heatingElementPower.label',
    showTooltip: 'heatingElementPower.tooltip',
    parse: parseOnlyPositiveIntegerNumbers,
    className: 'm-input',
    component: CustomTextInputHoc,
    isInteger: true
  },
  nominal_power: {
    label: 'nominal_power.label',
    showTooltip: 'nominal_power.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloatWithTwoDecimal]
  },
  installed_power: {
    label: 'installed_power.label',
    showTooltip: 'installed_power.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloatWithTwoDecimal]
  },
  limitedFeedInPower: {
    label: 'limitedFeedInPower.label',
    showTooltip: 'limitedFeedInPower.tooltip',
    className: 'm-input',
    component: Select,
    validate: [required],
    options: () => [
      { label: i18n.t('limitedFeedInPower.options.NominalPower'), value: 'NominalPower' },
      { label: i18n.t('limitedFeedInPower.options.InstalledPower'), value: 'InstalledPower' }
    ]
  },
  fallback: {
    label: 'fallback.label',
    showTooltip: 'fallback.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, from0to100]
  },
  input_device: {
    label: 'inputDevice',
    placeholder: 'selectInputDevice',
    className: 'm-input',
    component: Select,
    validate: [required],
    required: true,
    options: () => []
  },
  controlled_devices: {
    label: 'affectedDevices',
    placeholder: 'selectAffectedDevices',
    className: 'm-input',
    component: MultipleSelect,
    validate: [requiredMultiple],
    required: true,
    options: () => []
  },
  limitedFeedInMode: {
    showTooltip: 'limitedFeedInMode.tooltip',
    className: 'm-input',
    component: RadioButtons,
    validate: [required],
    variants: [
      { label: 'limitedFeedInMode.options.0', value: 0 },
      { label: 'limitedFeedInMode.options.1', value: 1 },
      { label: 'limitedFeedInMode.options.2', value: 2 }
    ]
  },
  ExternalFeedInMode: {
    showTooltip: 'ExternalFeedInMode.tooltip',
    className: 'm-input',
    component: RadioButtons,
    validate: [required],
    variants: [
      { label: 'ExternalFeedInMode.options.0', value: 0 },
      { label: 'ExternalFeedInMode.options.1', value: 1 }
    ]
  },
  negativePricesFeedInMode: {
    showTooltip: 'negativePricesFeedInMode.tooltip',
    className: 'm-input',
    component: RadioButtons,
    validate: [required],
    variants: [
      { label: 'negativePricesFeedInMode.options.0', value: 0 },
      { label: 'negativePricesFeedInMode.options.1', value: 1 }
    ]
  },
  performance: {
    label: 'performance.label',
    showTooltip: 'performance.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, from0to100],
    required: true
  },
  absolute_power: {
    label: 'absolute_power.label',
    showTooltip: 'absolute_power.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloatWithTwoDecimal, minFloatValue1],
    required: true
  },
  comfortOffsetEnable3: {
    label: 'comfortOffsetEnable3.label',
    showTooltip: 'comfortOffsetEnable3.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  ComfortOffsetMinimum3: {
    label: 'ComfortOffsetMinimum3.label',
    showTooltip: 'ComfortOffsetMinimum3.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validateNumbersWith1DigitStep05],
    parse: parseFloatMinusAndPlusNumbers,
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable3',
        values: [true]
      }
    ]
  },
  ComfortOffsetNormal3: {
    label: 'ComfortOffsetNormal3.label',
    showTooltip: 'ComfortOffsetNormal3.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validateNumbersWith1DigitStep05],
    parse: parseFloatMinusAndPlusNumbers,
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable3',
        values: [true]
      }
    ]
  },
  ComfortOffsetMaximum3: {
    label: 'ComfortOffsetMaximum3.label',
    showTooltip: 'ComfortOffsetMaximum3.tooltip',
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateNumbersWith1DigitStep05],
    parse: parseFloatMinusAndPlusNumbers,
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable3',
        values: [true]
      }
    ]
  },
  comfortOffsetEnable4: {
    label: 'comfortOffsetEnable4.label',
    showTooltip: 'comfortOffsetEnable4.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  ComfortOffsetMinimum4: {
    label: 'ComfortOffsetMinimum4.label',
    showTooltip: 'ComfortOffsetMinimum4.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validateNumbersWith1DigitStep05],
    parse: parseFloatMinusAndPlusNumbers,
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable4',
        values: [true]
      }
    ]
  },
  ComfortOffsetNormal4: {
    label: 'ComfortOffsetNormal4.label',
    showTooltip: 'ComfortOffsetNormal4.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, validateNumbersWith1DigitStep05],
    parse: parseFloatMinusAndPlusNumbers,
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable4',
        values: [true]
      }
    ]
  },
  ComfortOffsetMaximum4: {
    label: 'ComfortOffsetMaximum4.label',
    showTooltip: 'ComfortOffsetMaximum4.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'comfortOffsetEnable4',
        values: [true]
      }
    ]
  },
  poolTemperatureEnabled: {
    label: 'poolTemperatureEnabled.label',
    showTooltip: 'poolTemperatureEnabled.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  poolTempNormal: {
    label: 'poolTempNormal.label',
    showTooltip: 'poolTempNormal.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    enabledIf: [
      {
        fieldID: 'poolTemperatureEnabled',
        values: [true]
      }
    ]
  },
  poolTempExcess: {
    label: 'poolTempExcess.label',
    showTooltip: 'poolTempExcess.tooltip',
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    className: 'm-input',
    component: CustomTextInputHoc,
    isFloat: true,
    validate: [required, validateFloat],
    enabledIf: [
      {
        fieldID: 'poolTemperatureEnabled',
        values: [true]
      }
    ]
  },
  heatpumpPower: {
    label: 'heatpumpPower.label',
    showTooltip: 'heatpumpPower.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required]
  },
  WPSystemModule: {
    label: 'WPSystemModule.label',
    showTooltip: 'WPSystemModule.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  maxReservedSoc: {
    label: 'maxReservedSoc.label',
    showTooltip: 'maxReservedSoc.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, from0to100]
  },
  minReservedSoc: {
    label: 'minReservedSoc.label',
    showTooltip: 'minReservedSoc.tooltip',
    parse: parsePositiveIntegerOrZero,
    className: 'm-input',
    component: CustomTextInput,
    validate: [required, from0to100]
  },
  RippleControlReceiverMode: {
    showTooltip: 'RippleControlReceiverMode.tooltip',
    component: RadioButtons,
    variants: [
      { label: 'RippleControlReceiverMode.options.0', value: 0 },
      { label: 'RippleControlReceiverMode.options.1', value: 1 },
      { label: 'RippleControlReceiverMode.options.2', value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  activityBasedOnPower: {
    label: 'activityBasedOnPower.label',
    showTooltip: 'activityBasedOnPower.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  SGReadyExcessState: {
    label: 'SGReadyExcessState.label',
    showTooltip: 'SGReadyExcessState.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('SGReadyExcessState.options.0'), value: 0 },
      { label: i18n.t('SGReadyExcessState.options.1'), value: 1 },
      { label: i18n.t('SGReadyExcessState.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  communicationProtocol: {
    label: 'communicationProtocol.label',
    showTooltip: 'communicationProtocol.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('communicationProtocol.options.0'), value: 0 },
      { label: i18n.t('communicationProtocol.options.1'), value: 1 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  farmingTopology: {
    label: 'farmingTopology.label',
    showTooltip: 'farmingTopology.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  directComfortOffsetEnable: {
    label: 'directComfortOffsetEnable.label',
    showTooltip: 'directComfortOffsetEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  directComfortOffsetMinimum: {
    label: 'directComfortOffsetMinimum.label',
    showTooltip: 'directComfortOffsetMinimum.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'directComfortOffsetEnable',
        values: [true]
      }
    ]
  },
  directComfortOffsetNormal: {
    label: 'directComfortOffsetNormal.label',
    showTooltip: 'directComfortOffsetNormal.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'directComfortOffsetEnable',
        values: [true]
      }
    ]
  },
  directComfortOffsetMaximum: {
    label: 'directComfortOffsetMaximum.label',
    showTooltip: 'directComfortOffsetMaximum.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parsePositiveIntegerOrZero,
    validate: [required],
    required: true,
    enabledIf: [
      {
        fieldID: 'directComfortOffsetEnable',
        values: [true]
      }
    ]
  },
  RctMode: {
    label: 'RctMode.label',
    showTooltip: 'RctMode.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('RctMode.options.0'), value: 0 },
      { label: i18n.t('RctMode.options.1'), value: 1 },
      { label: i18n.t('RctMode.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  RctID: {
    label: 'RctID.label',
    showTooltip: 'RctID.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required]
  },
  VitogateVariant: {
    label: 'VitogateVariant.label',
    showTooltip: 'VitogateVariant.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('VitogateVariant.options.0'), value: 0 },
      { label: i18n.t('VitogateVariant.options.1'), value: 1 },
      { label: i18n.t('VitogateVariant.options.2'), value: 2 },
      { label: i18n.t('VitogateVariant.options.3'), value: 3 },
      { label: i18n.t('VitogateVariant.options.4'), value: 4 },
      { label: i18n.t('VitogateVariant.options.5'), value: 5 },
      { label: i18n.t('VitogateVariant.options.6'), value: 6 },
      { label: i18n.t('VitogateVariant.options.7'), value: 7 },
      { label: i18n.t('VitogateVariant.options.8'), value: 8 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  heimLWMapro: {
    label: 'heimLWMapro.label',
    showTooltip: 'heimLWMapro.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('heimLWMapro.options.0'), value: 0 },
      { label: i18n.t('heimLWMapro.options.1'), value: 1 },
      { label: i18n.t('heimLWMapro.options.2'), value: 2 },
      { label: i18n.t('heimLWMapro.options.3'), value: 3 },
      { label: i18n.t('heimLWMapro.options.4'), value: 4 }
    ],
    className: 'm-input',
    validate: [required],
    required: true
  },
  ski: {
    label: 'ski.label',
    className: 'm-input',
    component: SkiSearchField,
    validate: [required]
  },
  connectorID: {
    label: 'connectorID.label',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseIntegerNumbers,
    validate: [required]
  },
  inputDeviceFunction: {
    label: 'inputDeviceFunction.label',
    className: 'm-input',
    placeholder: 'selectFunction',
    component: Select,
    validate: [required],
    required: true,
    options: () => [
      { label: i18n.t('inputDeviceFunction.options.0'), value: 0 },
      { label: i18n.t('inputDeviceFunction.options.1'), value: 1 },
      { label: i18n.t('inputDeviceFunction.options.2'), value: 2 },
      { label: i18n.t('inputDeviceFunction.options.3'), value: 3 },
      { label: i18n.t('inputDeviceFunction.options.4'), value: 4 },
      { label: i18n.t('inputDeviceFunction.options.5'), value: 5 },
      { label: i18n.t('inputDeviceFunction.options.6'), value: 6 },
      { label: i18n.t('inputDeviceFunction.options.7'), value: 7 },
      { label: i18n.t('inputDeviceFunction.options.8'), value: 8 },
      { label: i18n.t('inputDeviceFunction.options.9'), value: 9 }
    ]
  },
  batteryMode: {
    label: 'batteryMode.label',
    showTooltip: 'batteryMode.tooltip',
    component: Select,
    options: ({ allowedBatteryModeOptions, license, tariffType }) => {
      const options = [
        { label: i18n.t('batteryMode.options.0'), value: 0 },
        { label: i18n.t('batteryMode.options.5'), value: 5 },
        { label: i18n.t('batteryMode.options.1'), value: 1 },
        { label: i18n.t('batteryMode.options.2'), value: 2 },
        { label: i18n.t('batteryMode.options.3'), value: 3 },
        { label: i18n.t('batteryMode.options.4'), value: 4 }
      ].filter(({ value }) => allowedBatteryModeOptions?.includes(value)) // filtering battery modes based on device doc
        .filter(({ value }) => (tariffType !== 'dynamic' ? ![4].includes(value) : true)); // filtering battery modes based on tariff type

      if (license?.restrictions?.allowedModes?.batteryMode) {
        return getOptionsWithRestrictions(options, license.restrictions.allowedModes.batteryMode); // filtering battery modes based on license restrictions
      }

      return options;
    },
    className: 'm-input',
    validate: [required],
    required: true
  },
  batteryManualMode: {
    label: 'batteryManualMode.label',
    showTooltip: 'batteryManualMode.tooltip',
    component: Select,
    options: () => [
      { label: i18n.t('batteryManualMode.options.0'), value: 0 },
      { label: i18n.t('batteryManualMode.options.1'), value: 1 },
      { label: i18n.t('batteryManualMode.options.2'), value: 2 }
    ],
    className: 'm-input',
    validate: [required],
    isFieldVisible: false,
    required: true,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [3]
      }
    ],
    switchAction: {
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 3
    }
  },
  upperSocLimit: {
    label: 'upperSocLimit.label',
    showTooltip: 'upperSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [3]
      }
    ],
    switchAction: {
      defaultValue: 95,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 3
    }
  },
  lowerSocLimit: {
    label: 'lowerSocLimit.label',
    showTooltip: 'lowerSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [3]
      }
    ],
    switchAction: {
      defaultValue: 15,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 3
    }
  },
  dischargeSocLimit: {
    label: 'dischargeSocLimit.label',
    showTooltip: 'dischargeSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [1]
      }
    ],
    switchAction: {
      defaultValue: 30,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 1
    }
  },
  chargingSocLimit: {
    label: 'chargingSocLimit.label',
    showTooltip: 'chargingSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [1]
      }
    ],
    switchAction: {
      defaultValue: 100,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 1
    }
  },
  morningSocLimit: {
    label: 'morningSocLimit.label',
    showTooltip: 'morningSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [1]
      }
    ],
    switchAction: {
      defaultValue: 80,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 1
    }
  },
  peakShavingSocDischargeLimit: {
    label: 'peakShavingSocDischargeLimit.label',
    showTooltip: 'peakShavingSocDischargeLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [2]
      }
    ],
    switchAction: {
      defaultValue: 10,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 2
    }
  },
  peakShavingSocMaxLimit: {
    label: 'peakShavingSocMaxLimit.label',
    showTooltip: 'peakShavingSocMaxLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [2]
      }
    ],
    switchAction: {
      defaultValue: 40,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 2
    }
  },
  peakShavingMaxGridPower: {
    label: 'peakShavingMaxGridPower.label',
    showTooltip: 'peakShavingMaxGridPower.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [2]
      }
    ],
    switchAction: {
      defaultValue: '0',
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 2
    }
  },
  peakShavingRechargePower: {
    label: 'peakShavingRechargePower.label',
    showTooltip: 'peakShavingRechargePower.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [2]
      }
    ],
    switchAction: {
      defaultValue: '0',
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 2
    }
  },
  tariffPriceLimit: {
    label: 'tariffPriceLimit.label',
    showTooltip: 'tariffPriceLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    isFloat: true,
    validate: [required],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [4]
      }
    ],
    switchAction: {
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 4
    }
  },
  tariffPriceLimitSocMax: {
    label: 'tariffPriceLimitSocMax.label',
    showTooltip: 'tariffPriceLimitSocMax.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [4]
      }
    ],
    switchAction: {
      defaultValue: 60,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 4
    }
  },
  tariffPriceLimitForecast: {
    label: 'tariffPriceLimitForecast.label',
    showTooltip: 'tariffPriceLimitForecast.tooltip',
    className: 'm-input',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    type: 'checkbox',
    component: Checkbox,
    parse: parseBoolean,
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [4]
      }
    ],
    switchAction: {
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 4
    }
  },
  powerCharge: {
    label: 'powerCharge.label',
    showTooltip: 'powerCharge.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required],
    required: true,
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [3]
      }
    ],
    switchAction: {
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 3
    }
  },
  powerDischarge: {
    label: 'powerDischarge.label',
    showTooltip: 'powerDischarge.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required],
    required: true,
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [3]
      }
    ],
    switchAction: {
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 3
    }
  },
  standardStandaloneAllowed: {
    label: 'standardStandaloneAllowed.label',
    showTooltip: 'standardStandaloneAllowed.tooltip',
    className: 'm-input',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    type: 'checkbox',
    component: Checkbox,
    parse: parseBoolean,
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [5]
      }
    ],
    switchAction: {
      defaultValue: true,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 5
    }
  },
  standardLowerSocLimit: {
    label: 'standardLowerSocLimit.label',
    showTooltip: 'standardLowerSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [5]
      }
    ],
    switchAction: {
      defaultValue: 10,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 5
    }
  },
  standardUpperSocLimit: {
    label: 'standardUpperSocLimit.label',
    showTooltip: 'standardUpperSocLimit.tooltip',
    className: 'm-input',
    component: () => {},
    parse: parseOnlyPositiveIntegerOrZero,
    validate: [required, from0to100],
    isFieldVisible: false,
    enabledIf: [
      {
        fieldID: 'batteryMode',
        values: [5]
      }
    ],
    switchAction: {
      defaultValue: 90,
      checkIfEnabled: (deviceType, switchingMode) => ['Battery'].includes(deviceType) && switchingMode === 5
    }
  },
  ComfortFeedInTempEnable: {
    label: 'ComfortFeedInTempEnable.label',
    showTooltip: 'ComfortFeedInTempEnable.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  ComfortFeedInTempOverproduction: {
    label: 'ComfortFeedInTempOverproduction.label',
    showTooltip: 'ComfortFeedInTempOverproduction.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    isFloat: true,
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    validate: [required, validateFloat],
    required: true,
    enabledIf: [
      {
        fieldID: 'ComfortFeedInTempEnable',
        values: [true]
      }
    ]
  },
  heatingStiebelComfort: {
    label: 'heatingStiebelComfort.label',
    showTooltip: 'heatingStiebelComfort.tooltip',
    className: 'm-input',
    component: Select,
    options: () => [
      { label: i18n.t('heatingStiebelComfort.options.0'), value: 0 },
      { label: i18n.t('heatingStiebelComfort.options.1'), value: 1 }
    ],
    enabledIf: [
      {
        fieldID: 'ComfortFeedInTempEnable',
        values: [true]
      }
    ]
  },
  hvacID: {
    label: 'hvacID.label',
    showTooltip: 'hvacID.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required]
  },
  nominalACPower: {
    label: 'nominalACPower.label',
    showTooltip: 'nominalACPower.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    isFloat: true,
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    validate: [required, validateFloatWithTwoDecimal],
    required: true
  },
  swaytronicInverterType: {
    label: 'swaytronicInverterType.label',
    showTooltip: 'swaytronicInverterType.tooltip',
    className: 'm-input',
    component: Select,
    options: () => [
      { label: i18n.t('swaytronicInverterType.options.0'), value: 0 },
      { label: i18n.t('swaytronicInverterType.options.1'), value: 1 }
    ]
  },
  pixiiType: {
    label: 'pixiiType.label',
    showTooltip: 'pixiiType.tooltip',
    className: 'm-input',
    component: Select,
    options: () => [
      { label: i18n.t('pixiiType.options.0'), value: 0 },
      { label: i18n.t('pixiiType.options.1'), value: 1 }
    ]
  },
  socEstimationEnabled: {
    label: 'socEstimationEnabled.label',
    showTooltip: 'socEstimationEnabled.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox
  },
  evseUUID: {
    label: 'evseUUID.label',
    showTooltip: 'evseUUID.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required]
  },
  edgeDeviceID: {
    label: 'edgeDeviceID.label',
    showTooltip: 'edgeDeviceID.tooltip',
    className: 'm-input',
    component: CustomTextInput,
    validate: [required]
  },
  digitalInputEnabled: {
    label: 'digitalInputEnabled.label',
    showTooltip: 'digitalInputEnabled.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox,
    validate: [required]
  },
  deepDischargeFunction: {
    label: 'deepDischargeFunction.label',
    showTooltip: 'deepDischargeFunction.tooltip',
    parse: parseBoolean,
    className: 'm-input',
    type: 'checkbox',
    labelClass: 'm-checkbox terms m-checkbox--light remem-checkbox',
    component: Checkbox,
    validate: [required]
  },
  chargeCapacity: {
    label: 'chargeCapacity.label',
    showTooltip: 'chargeCapacity.tooltip',
    className: 'm-input',
    isFloat: true,
    parse: parseFloatNumbersWithOneDigitAfterPoint,
    validate: [required, validateFloatWithTwoDecimal],
    component: CustomTextInput,
    required: true
  },
  controlTarget: {
    label: 'controlTarget.label',
    showTooltip: 'controlTarget.tooltip',
    className: 'm-input',
    component: Select,
    options: () => [
      { label: 'mA', value: 0 },
      { label: 'A', value: 1 }
    ]
  }
};

export default additionalFields;

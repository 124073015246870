/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { useDispatch } from 'react-redux';

import CustomSelectOption from '../../../components/ReduxFormFields/CustomSelect/CustomSelectOption';
import { BATTERY_MODE_MODAL_ID, openModalWindow } from '../../ModalWindow/slice';
import { Tooltip } from '../../../components';
import i18n from '../../../i18n';
import { APP_ID, ASKOMA_APP_ID, EKZ_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../config';

/**
 * Renders selector for switch number on device
 * @memberof module:UserDevices
 */
const Select = ({
  input = {},
  label = '',
  meta: { touched, error },
  disabled = false,
  required,
  options,
  showTooltip = '',
  placeholder = '',
  deviceName: _deviceName,
  license,
  mode, // add device or edit device
  isLoadGuardConfigured,
  ...custom
}) => {
  const dispatch = useDispatch();
  const optionsArray = typeof options === 'function' ? options({ ...custom, isLoadGuardConfigured, license, dispatch }) : options;
  const onChange = (selected) => input.onChange(selected ? selected.value : null);
  const labelLocal = i18n.exists(label) ? i18n.t(label) : label;
  const value = optionsArray.find((option) => String(option.value) === String(input.value)) || null;
  React.useEffect(() => {
    if (input.name === 'data.heatPumpChargingMode' && value?.disabled) {
      input.onChange(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const openBatteryModeModal = () => {
    dispatch(openModalWindow({ modalID: BATTERY_MODE_MODAL_ID, data: { batteryMode: input.value, mode } }));
  };

  return (
    <>
      {!!showTooltip && (
        <div className="switch-tooltip select">
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </div>
      )}
      <div className="flags-select-label">{required ? `${labelLocal} *` : labelLocal}</div>
      <ReactSelect
        clearable={false}
        {...input}
        {...custom}
        value={value}
        onChange={onChange}
        onBlur={(event) => event.preventDefault()}
        isDisabled={disabled}
        className={disabled ? 'react-select-container disabeldSelect' : 'react-select-container'}
        classNamePrefix="react-select"
        placeholder={i18n.exists(placeholder) ? i18n.t(placeholder) : placeholder || labelLocal}
        options={optionsArray}
        isOptionDisabled={(option) => option.disabled}
        components={{ Option: CustomSelectOption }}
      />
      {touched && error && <div className="inputCustomWarning">{i18n.t(error)}</div>}
      {input.name === 'data.batteryMode' && [1, 2, 3, 4, 5].filter((item) => ([SOLAR_MANAGER_APP_ID, ASKOMA_APP_ID, EKZ_APP_ID].includes(APP_ID) ? true : ![2, 4].includes(item))).includes(input.value) && (
        <div className="battery-mode-btn-wrapper">
          <button type="button" className="button button-third" onClick={openBatteryModeModal}>
            {i18n.t('editBatteryMode')}
          </button>
        </div>
      )}
    </>
  );
};

Select.propTypes = {
  input: PropTypes.instanceOf(Object),
  label: PropTypes.string,
  meta: PropTypes.instanceOf(Object).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  showTooltip: PropTypes.string,
  options: PropTypes.PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.func]).isRequired,
  placeholder: PropTypes.string,
  deviceName: PropTypes.string,
  license: PropTypes.instanceOf(Object)
};

export default Select;
